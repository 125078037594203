import axios from "axios";
import "../../config";
// constantes
const dataInicial = {
  employees: [],
  employee: {},
  loading: false,
  response: {},
};

// types
const LOADING_EMPLOYEE = "LOADING_EMPLOYEE";
const GET_EMPLOYEES = "GET_EMPLOYEES";
const ADD_EMPLOYEES = "ADD_EMPLOYEES";
const SET_EMPLOYEE = "SET_EMPLOYEE";
const GET_EMPLOYEE = "GET_EMPLOYEE";
const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";
const DELETE_EMPLOYEE = "DELETE_EMPLOYEE";
const CLEAR_STATUS_ACTION = "CLEAR_STATUS_ACTION";

const GET_ERROR_EMPLOYEES = "GET_ERROR_EMPLOYEES";

// reducer
export default function dataReducer(state = dataInicial, action) {
  switch (action.type) {
    case LOADING_EMPLOYEE:
      return { ...state, loading: true };
    case GET_EMPLOYEES:
      return { ...state, employees: action.payload, loading: false };
    case GET_EMPLOYEE:
      return { ...state, employee: action.payload, loading: false };
    case SET_EMPLOYEE:
      return { ...state, employee: action.payload};
    case ADD_EMPLOYEES:
      return {
        ...state,
        employees: [...state.employees, action.payload],
        loading: false,
        response: {
          status: 200,
          success: true,
          statusText: ["La mascota se ha agregado correctamente"],
        },
      };
    case UPDATE_EMPLOYEE:
      return {
        ...state,
        employees: [
          ...state.employees.filter(
            (element) => element.id.toString() !== action.payload.id.toString()
          ),
          action.payload,
        ],
        response: {
          status: 200,
          success: true,
          statusText: ["La mascota fue actualizada correctamente"],
        },
        loading: false,
      };
    case DELETE_EMPLOYEE: {
      return {
        ...state,
        employees: [
          ...state.employees.filter((element) => element.id != action.payload),
        ],
        loading: false,
      };
    }
    case CLEAR_STATUS_ACTION: {
      return {
        ...state,
        response: {},
      };
    }
    case GET_ERROR_EMPLOYEES:
      return {
        ...state,
        response: {
          status: 400,
          statusText: ["Ocurrio un error al crear la mascota."],
        },
      };
    default:
      return state;
  }
}

// actions
export const addEmployeeData =
  (employeeData, image = "") =>
  async (dispatch, getState) => {
    dispatch({
      type: LOADING_EMPLOYEE,
    });
    var dataSend = new FormData();
    dataSend.append("name", employeeData.name);
    dataSend.append("lastname", employeeData.lastname);
    dataSend.append("color", employeeData.color);
    dataSend.append("gender", employeeData.gender);
    dataSend.append("date_hiring", employeeData.date_hiring);
    dataSend.append("address", employeeData.address);
    dataSend.append("phone", employeeData.phone);
    dataSend.append("position", employeeData.position);
    dataSend.append("description", employeeData.description);
    if (image) {
      dataSend.append("image", image);
    }
    try {
      const res = await axios.post(
        global.config.env.API_SERVER + "employees",
        dataSend,
        {
          headers: {
            "Api-Token": localStorage.getItem("Api_Token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(res);
      dispatch({
        type: ADD_EMPLOYEES,
        payload: res.data.employee,
      });
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_ERROR_EMPLOYEES });
    }
  };
export const updateEmployeeData =
  (employeeData, image = null) =>
  async (dispatch, getState) => {
    dispatch({
      type: LOADING_EMPLOYEE,
    });
    const data = {
      name:employeeData.name,
      lastname:employeeData.lastname,
      color:employeeData.color,
    }
    try {
      const res = await axios.post(
        global.config.env.API_SERVER + "employees/" + employeeData.id,
        data,
        {
          headers: {
            "Api-Token": localStorage.getItem("Api_Token"),
          },
        }
      );
      dispatch({
        type: UPDATE_EMPLOYEE,
        payload: res.data.employee,
      });
    } catch (error) {
      dispatch({
        type: GET_ERROR_EMPLOYEES,
      });
    }
  };
export const getEmployeesData = () => async (dispatch, getState) => {
  if (getState().users.userStats?.data.code !== 200) {
    return null;
  }
  if (getState().employees.employees.length === 0) {
    dispatch({
      type: LOADING_EMPLOYEE,
    });
    try {
      const res = await axios.get(global.config.env.API_SERVER + "employees", {
        headers: {
          "Api-Token": localStorage.getItem("Api_Token"),
        },
      });
      dispatch({
        type: GET_EMPLOYEES,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  }
};
export const setEmployeeAction = (employee) => async (dispatch, getState) => {
  dispatch({
    type: SET_EMPLOYEE,
    payload: employee,
  });
};
export const deleteEmployee = (id) => async (dispatch, getState) => {
  dispatch({
    type: LOADING_EMPLOYEE,
  });
  try {
    const res = await axios.delete(
      global.config.env.API_SERVER + "employees/" + id,
      {
        headers: {
          "Api-Token": localStorage.getItem("Api_Token"),
        },
      }
    );
    dispatch({
      type: DELETE_EMPLOYEE,
      payload: id,
    });
  } catch (error) {
    console.log(error);
  }
};
export const clearPetsStatus = () => async (dispatch, getState) => {
  dispatch({
    type: CLEAR_STATUS_ACTION,
  });
};
