import axios from "axios";
import "../../config";
// constantes
const dataInicial = {
  shifts: [{}],
  shifts_date:[],
  history_shifts:[],
  graph_resume:[],
  shift: {},
  loading: false,
  blogCreated: {},
  response:{},
  loading_date:false,
  loading_history:false,
};

// types
const GET_SHIFTS = "GET_SHIFTS";
const GET_HISTORY_SHIFTS = "GET_HISTORY_SHIFTS";
const GET_GRAPH_RESUME = "GET_GRAPH_RESUME";
const GET_SHIFT = "GET_SHIFT";
const GET_SHIFTS_DATE = "GET_SHIFTS_DATE";
const ADD_SHIFT = "ADD_SHIFT";
const UPDATE_SHIFT = "UPDATE_SHIFT";
const ADD_SHIFT_FAILED = "ADD_SHIFT_FAILED";
const DELETE_SHIFT = "DELETE_SHIFT";
const LOADING_SHIFTS = "LOADING_SHIFTS";
const LOADING_SHIFTS_DATE = "LOADING_SHIFTS_DATE";
const LOADING_SHIFTS_HISTORY = "LOADING_SHIFTS_HISTORY";
const CLEAR_STATUS_ACTION = "CLEAR_STATUS_ACTION";

// reducer
export default function dataReducer(state = dataInicial, action) {
  switch (action.type) {
    case LOADING_SHIFTS:
      return { ...state, loading: true };
    case LOADING_SHIFTS_DATE:
      return { ...state, loading_date: true };
    case LOADING_SHIFTS_HISTORY:
      return { ...state, loading_history: true };
    case GET_SHIFTS:
      return { ...state, shifts: action.payload, loading:false};
    case GET_SHIFTS_DATE:
      return { ...state, shifts_date: action.payload, loading:false,loading_date:false};
    case GET_HISTORY_SHIFTS:
      return { ...state, history_shifts: action.payload, loading_history:false};
    case GET_GRAPH_RESUME:
      return { ...state, graph_resume: action.payload.data, loading:false};
    case GET_SHIFT:
      return { ...state, shift: action.payload, loading:false};
    case ADD_SHIFT:
      return {
        ...state,
        shifts: [...state.shifts, action.payload],
        loading: false,
        response:{
          status:200,
          success:true,
          statusText:["El turno se ha agregado correctamente"]
        }
      };
    case UPDATE_SHIFT:
      return {
        ...state,
        shifts_date: [
          ...state.shifts_date.filter((element) => element.shift_id.toString() !== action.payload.shift_id.toString()),action.payload,
        ],
        // shifts: [
        //   ...state.shifts.filter((element) => element.shift_id.toString() !== action.payload.shift_id.toString()),action.payload,
        // ],
        loading: false,
        response:{
          status:200,
          success:true,
          statusText:["El turno se ha actualizado correctamente"]
        }
      };
    case DELETE_SHIFT:
      return {
        ...state,
        shifts: [
          ...state.shifts.filter((element) => element.shift_id.toString() !== action.payload.id.toString()),
        ],
        shifts_date: [
          ...state.shifts_date.filter((element) => element.shift_id.toString() !== action.payload.id.toString()),
        ],
        loading: false,
        response:{
          status:200,
          success:true,
          statusText:["El turno se ha eliminado correctamente"]
        }
      };
    case CLEAR_STATUS_ACTION:
      return {
        ...state,
        response:{}
      };
    default:
      return state;
  }
}

// actions
export const addShiftData = (shiftData) => async (dispatch, getState) => {
  dispatch({
    type: LOADING_SHIFTS,
  });



  var dataSend = shiftData;

  dataSend.price = dataSend.price ? dataSend.price : '0';
  dataSend.washed = dataSend.washed ? dataSend.washed : false;
  dataSend.cut_type = dataSend.cut_type ? dataSend.cut_type : 'Sin Corte';
  dataSend.fragrance = dataSend.fragrance ? dataSend.fragrance : false;
  dataSend.search = dataSend.search ? dataSend.search : false;
  dataSend.status = 1
  dataSend.waiting = dataSend.waiting === 0 || dataSend.waiting === '0' || dataSend.waiting === false ? 0 : 1;
  // var dataSend = new FormData();
  // dataSend.append("pet_id", shiftData.pet_id);
  // dataSend.append("cut_type", shiftData.cut_type ? shiftData.cut_type : 'Sin Definir');
  // dataSend.append("price", shiftData.price ? shiftData.price : '0');
  // dataSend.append("washed", shiftData.washed ? shiftData.washed : false);
  // dataSend.append("fragrance", shiftData.fragrance);
  // dataSend.append("date", shiftData.date);
  // dataSend.append("description", shiftData.description ? shiftData.description : 'Sin Definir');
  // dataSend.append("search", shiftData.search);
  try {
    const res = await axios.post(
      global.config.env.API_SERVER + "shifts",
      dataSend,
      {
        headers: {
          "Api-Token": localStorage.getItem("Api_Token"),
        },
      }
    );
    dispatch({
      type: ADD_SHIFT,
      payload: res.data.shift,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ADD_SHIFT_FAILED,
      payload: { data: "" },
    });
  }
};
export const updateShiftData = (shiftData) => async (dispatch, getState) => {
    dispatch({
      type: LOADING_SHIFTS,
    });

    try {
      const res = await axios.post(
        global.config.env.API_SERVER + "shifts/" + shiftData.id,
        shiftData,
        {
          headers: {
            "Api-Token": localStorage.getItem("Api_Token"),
          },
        }
      );
      dispatch({
        type: UPDATE_SHIFT,
        payload: res.data.shift[0],
      });
    } catch (error) {
      dispatch({
        type: ADD_BLOG_FAILED,
        payload: { data: "" },
      });
    }
  };
export const updateShiftStatus = (shift_id,status) => async (dispatch, getState) => {
    dispatch({
      type: LOADING_SHIFTS,
    });
    try {
      const res = await axios.put(global.config.env.API_SERVER + "shifts/" + shift_id,
        {"status":status},
        {
          headers: {
            "Api-Token": localStorage.getItem("Api_Token"),
          },
        }
      );
      dispatch({
        type: UPDATE_SHIFT,
        payload: res.data.shift[0],
      });
    } catch (error) {
      console.log(error)
    }
};
export const getHistoryShiftsData = () => async (dispatch, getState) => {
  if(getState().users.userStats?.data.code !== 200){return null}
  if(getState().shifts.history_shifts?.length > 0){return null}
  if(getState().shifts.loading_history){return null}
  dispatch({type: LOADING_SHIFTS_HISTORY});
  try {
    const res = await axios.get(global.config.env.API_SERVER + "shifts/search", {
      headers: {
        "Api-Token": localStorage.getItem("Api_Token"),
      },
    });
    console.log(res)
    dispatch({
      type: GET_HISTORY_SHIFTS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};
export const getShiftsData = () => async (dispatch, getState) => {
  if(getState().users.userStats?.data.code !== 200){return null}
  // if(getState().shifts.shifts?.length > 0){return null}
  // if(getState().shifts.loading){return null}
  dispatch({type: LOADING_SHIFTS});
  try {
    const res = await axios.get(global.config.env.API_SERVER + "shifts", {
      headers: {
        "Api-Token": localStorage.getItem("Api_Token"),
      },
    });
    dispatch({
      type: GET_SHIFTS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};
export const getGraphShiftsAction = (from,to,employee_id = '') => async (dispatch, getState) => {
  if(getState().users.userStats?.data.code !== 200){return null}
  dispatch({type: LOADING_SHIFTS});
  try {
    const res = await axios.get(global.config.env.API_SERVER + "shifts/search?&resume=true&from_date=" + from + "&to_date=" + to + (employee_id ? '&employee_id='+employee_id:'') , {
      headers: {
        "Api-Token": localStorage.getItem("Api_Token"),
      },
    });
    console.log(res)
    dispatch({
      type: GET_GRAPH_RESUME,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};
export const deleteShift = (id) => async (dispatch, getState) => {
  dispatch({type: LOADING_SHIFTS});
  try {
    const res = await axios.delete(global.config.env.API_SERVER + "shifts/" + id, {
      headers: {
        "Api-Token": localStorage.getItem("Api_Token"),
      },
    });
    console.log(res)
    dispatch({
      type: DELETE_SHIFT,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};
export const getShiftData = (id) => async (dispatch, getState) => {
  if(getState().users.userStats?.data.code !== 200){return null}
  dispatch({type: LOADING_SHIFTS});
  try {
    const res = await axios.get(global.config.env.API_SERVER + "shifts/" + id, {
      headers: {
        "Api-Token": localStorage.getItem("Api_Token"),
      },
    });
    dispatch({
      type: GET_SHIFT,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};
export const getShiftDate = (date) => async (dispatch, getState) => {
  if(getState().users.userStats?.data.code !== 200){return null}
  dispatch({type: LOADING_SHIFTS});
  dispatch({type: LOADING_SHIFTS_DATE});
  try {
    const res = await axios.get(global.config.env.API_SERVER + "shifts/search?date=" + date , {
      headers: {
        "Api-Token": localStorage.getItem("Api_Token"),
      },
    });
    console.log(res)
    dispatch({
      type: GET_SHIFTS_DATE,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
}
export const clearStatusAction = () => async (dispatch, getState) => {
  dispatch({
    type: CLEAR_STATUS_ACTION,
  });
}

