import { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../redux/reducers/userReducer";
import { clearClientStatus } from "../redux/reducers/clientsReducer";

import Header from "../components/header.jsx";
import Sidebar from "../components/sidebar.jsx";
import Panel from "../components/clients/update_clients"

import toast, { Toaster } from 'react-hot-toast';

const newClients = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const loading = useSelector((store) => store.users.loading);
    const response = useSelector((store) => store.clients.response);
    const logged = useSelector((store) => store.users.logged);

    useEffect(() => {
      // dispatch(getUserData());
      if(!localStorage.getItem("Api_Token")){
        history.push("/login")
      }
      if(!loading){
        if(!logged){
          history.push("/login")
        }
      }
    }, [loading]);

    useEffect(() => {
      if(response.status){
        if(response.status === 200){
          history.push("/clientes")
        }
        if(response.status === 400){
        }
      }
    } , [response]);

  return !loading ? (
    <div>
      <Toaster />
      <Header />
      <Sidebar />
      <div class="main-content-container container-fluid px-4">
        <Panel />
      </div>
    </div>
  ):'';
};

export default newClients;
