import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import Table from '../components/tables/detailPet'
import {useParams} from "react-router-dom";

import { getPetShiftsData } from "../redux/reducers/petsReducer";

const Home = () =>{
    const dispatch = useDispatch();
    const {id} = useParams();

    useEffect(() => {
        dispatch(getPetShiftsData(id))
    },[id]);

    return(
        <div class="main-content-container container-fluid px-4">
            <div class="row">
                <Table />
            </div>
        </div>
    )
}

export default Home