import {Provider} from 'react-redux'
import generateStore from './redux/store'

import { BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom"
import { AnimatedSwitch } from 'react-router-transition';


import Login from './views/login'
import Register from './views/register'
import Home from './views/home'
import Metricas from './views/metrics'
import Shifts from './views/shifts'
import NewShifts from './views/newShifts'
import UpdateShifts from './views/updateShifts'
import NewPets from './views/newPets'
import NewClients from './views/newClients'
import UpdateClients from './views/updateClients'
import DetailClient from './views/detailClient'
import DetailPet from './views/detailPet'
import Tables from './views/tables'
import Pets from './views/pets'
import UpdatePets from './views/updatePets'
import Clients from './views/clients'
import Payment from './views/payment'
import Settings from './views/settings'

import Header from './components/header.jsx'
import Sidebar from './components/sidebar.jsx'
import { useEffect } from 'react'
import './config';

import ToasterComponent from './components/ToastResponse'

function App() {
  const store = generateStore()

  return (
    <Router>
      <Provider store={store}>
      <ToasterComponent />
        <Switch>
          <Route path={'/login'} component={Login} exact></Route>          
          <Route path={'/register'} component={Register} exact></Route>          
          <Route path={'/'} component={Home} exact></Route>             
          <Route path={'/metricas'} component={Metricas} exact></Route>             
          <Route path={'/shifts'} component={Shifts} exact></Route>             
          <Route path={'/shifts/add'} component={NewShifts} exact></Route>             
          <Route path={'/shifts/update/:id'} component={UpdateShifts} exact></Route>             
          <Route path={'/pets/add'} component={NewPets} exact></Route>             
          <Route path={'/mascotas/actualizar/:id'} component={UpdatePets} exact></Route>             
          <Route path={'/clients/add'} component={NewClients} exact></Route>             
          <Route path={'/clientes/update/:id'} component={UpdateClients} exact></Route>             
          <Route path={'/payment/success'} component={Payment} exact />
          <Route path={'/payment'} component={Payment} exact />
          <Route path={'/payment'} component={Payment} exact />
          <Route path={'/history'} exact>
            <Header />  
            <Sidebar />   
            <Tables />
          </Route>             
          <Route path={'/mascotas'} exact>
            <Header />  
            <Sidebar />   
            <Pets />
          </Route>             
          <Route path={'/mascotas/:id'} exact>
            <Header />  
            <Sidebar />  
            <DetailPet />
          </Route>         
          <Route path={'/clientes'} exact>
            <Header />  
            <Sidebar />   
            <Clients />
          </Route>     
          <Route path={'/clientes/:id'} exact>
            <Header />  
            <Sidebar />  
            <DetailClient />
          </Route>         
          <Route path={'/ajustes'} component={Settings} exact></Route>             
        </Switch>
      </Provider>
    </Router>
  );
}

export default App;
