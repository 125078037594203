import { useEffect, useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { loginUser } from "../redux/reducers/userReducer";
import { getUserData } from "../redux/reducers/userReducer";

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userData = useSelector((store) => store.users.userStats)?.data;
  const logged = useSelector((store) => store.users.logged);
  const loading = useSelector((store) => store.users.loading);
  const errorMessage = useSelector((store) => store.users.errorMessage);

  const inputEmail = useRef();
  const inputPassword = useRef();

  const [buttonPress, setButtonPress] = useState(false);

  useEffect(() => {
    // dispatch(getUserData());
  }, []);

  useEffect(() => {
    if (localStorage.getItem("Api_Token") != "") {
      if (userData && userData.id && logged) {
        history.push("/");
      }
    }
  }, [userData]);


  return (
    <section class="login-block d-flex align-items-center animate__animated animate__fadeIn animate__slower">
      {loading ? (
        <section className="loading_section">
          <div class="sk-chase">
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
          </div>
        </section>
      ) : (
        ""
      )}
      <div className="register-section">
        <div class="container">
          <div class="row d-flex align-items-center">
            <div class="col-md-5 login-sec mt-5">
              <form class="login-form">
                <div class="form-group">
                  <label for="exampleInputPassword1" class="">
                    Email
                  </label>
                  <input
                    type="password"
                    class="form-control"
                    placeholder=""
                  />
                </div>
                <div className="d-block d-lg-flex justify-between">
                  <div class="form-group w-100 pr-lg-2">
                    <label for="exampleInputPassword1" class="">
                      Contraseña
                    </label>
                    <input
                      type="password"
                      class="form-control"
                      placeholder=""
                    />
                  </div>
                  <div class="form-group w-100 pl-lg-2">
                    <label for="exampleInputPassword1" class="">
                      Repetir contraseña
                    </label>
                    <input
                      type="password"
                      class="form-control"
                      placeholder=""
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1" class="">
                    Nombre y apellido
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1" class="">
                    Nombre de tu negocio
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                  />
                </div>
                <div className="d-block d-lg-flex justify-between">
                  <div class="form-group w-100 pr-lg-2">
                    <label for="exampleInputPassword1" class="">
                      Direccion
                    </label>
                    <input
                      type="password"
                      class="form-control"
                      placeholder=""
                    />
                  </div>
                  <div class="form-group w-100 pl-lg-2">
                    <label for="exampleInputPassword1" class="">
                      Teléfono
                    </label>
                    <input
                      type="password"
                      class="form-control"
                      placeholder=""
                    />
                  </div>
                </div>
                <div class="form-check">
                  <span
                    onClick={() =>
                      dispatch(
                        loginUser(
                          inputEmail.current.value,
                          inputPassword.current.value
                        )
                      ) + setButtonPress(true)
                    }
                    class="btn btn-login float-right"
                  >
                    Ingresar
                  </span>
                </div>
              </form>
                  <span className={"text-danger text-error d-block mt-5 " + (errorMessage == 403 && buttonPress ? "" : 'opacity-none')}> <i class="fas fa-times-circle"></i> Correo o contraseña incorrecta </span>
            </div>
            <div class="col-md-7 banner-sec">
              <div
                id="carouselExampleIndicators"
                class="carousel slide"
                data-ride="carousel"
                >
                <div class="carousel-inner" role="listbox">
                  <div class="carousel-item active"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
  );
};

export default Login;
