import Panel from "../components/shifts/list_shifts";
import Calendary from "../components/shifts/calendary_shfts";
import { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../redux/reducers/userReducer";
import { getPetsData } from "../redux/reducers/petsReducer";
import { getShiftsData } from "../redux/reducers/shiftsReducer";
import { getClientsData } from "../redux/reducers/clientsReducer";


import Header from "../components/header.jsx";
import Sidebar from "../components/sidebar.jsx";
import AddShift_Modal from '../components/modals/add_shifts_modal';

const Blog = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const loading = useSelector((store) => store.users.loading);
    const logged = useSelector((store) => store.users.logged);
    const clients = useSelector((store) => store.clients.clients);
    const pets = useSelector((store) => store.pets.pets);
  
    useEffect(() => {
      // dispatch(getUserData());
      dispatch(getPetsData());
      dispatch(getShiftsData());
      dispatch(getClientsData());
      if(!localStorage.getItem("Api_Token")){
        history.push("/login")
      }
      if(!loading){
        if(!logged){
          history.push("/login")
        }
      }
    }, [loading]);

  return !loading ? (
    <div>
      <Header />
      <Sidebar />
      <div class="main-content-container container-fluid px-4">
        <Calendary />
        <AddShift_Modal />
        {/* <Panel /> */}
      </div>
    </div>
  ):'';
};

export default Blog;
