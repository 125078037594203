import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Shift from "../shift";
import {
  getShiftDate,
  getShiftsData,
  updateShiftData,
  updateShiftStatus,
} from "../../redux/reducers/shiftsReducer";
import Calendar from "react-calendar";
import "../../../node_modules/react-calendar/dist/Calendar.css";
import Modal from "../modal-shift";
import { deleteShift } from "../../redux/reducers/shiftsReducer";
import ContentLoader from "react-content-loader";
import CALENDAR_SVG from "../../assets/images/calendary1.svg";
import { getEmployeesData } from "../../redux/reducers/employeesReducer";

// import "./App.css";

const Loader = (props) => (
  <ContentLoader
    speed={2}
    width={"100%"}
    height={"7vh"}
    viewBox="0 0 100% 7vh"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="5" y="5" rx="0" ry="0" width="100%" height="7vh" />
  </ContentLoader>
);

const listBlogs = () => {
  const allShifts = useSelector((store) => store.shifts.shifts);
  const shifts = useSelector((store) => store.shifts.shifts_date);
  const employees = useSelector((store) => store.employees.employees);
  const loading = useSelector((store) => store.shifts.loading);
  const loading_date = useSelector((store) => store.shifts.loading_date);
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date());
  const [calendaryOpen, setCalendaryOpen] = useState(false);

  const [filters, setFilters] = useState({
    pending: true,
    process: true,
    ended: true,
  });

  const handleChange = (date) => {
    setDate(date);
  };

  //function que convierte un date a un string dd-mm-yyyy
  const dateToString = (date) => {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }
    return day + "-" + month + "-" + year;
  };

  //function to converte date to string dd of month of year
  const dateToString2 = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day} de ${monthToString(month)} de ${year}`;
  };

  //function to convert number month to string month  (1 = enero)
  const monthToString = (month) => {
    switch (month) {
      case 1:
        return "Enero";
      case 2:
        return "Febrero";
      case 3:
        return "Marzo";
      case 4:
        return "Abril";
      case 5:
        return "Mayo";
      case 6:
        return "Junio";
      case 7:
        return "Julio";
      case 8:
        return "Agosto";
      case 9:
        return "Septiembre";
      case 10:
        return "Octubre";
      case 11:
        return "Noviembre";
      case 12:
        return "Diciembre";
    }
  };

  //function de un dato te devuelve la hora
  const getHours = (time, sumary = false) => {
    // @sumary == string response
    let hours = time ? time.split(" ")[1] : "";
    return sumary ? hours.replaceAll(":", "") : hours;
  };

  const getTotalPriceDay = (shifts) => {
    let total = 0;
    for (const iterator of shifts) {
      total += iterator.shift_price;
    }
    return total;
  };

  const getWaitings = (shifts) => {
    return shifts.filter(
      (element) =>
        element?.shift_waiting?.toString() === "true" ||
        element?.shift_waiting?.toString() === "1"
    );
  };

  useEffect(() => {
    dispatch(getShiftDate(dateToString(date)));
  }, [date]);

  const setShiftStatus = (shift, status) => {
    dispatch(updateShiftStatus(shift.shift_id, status));
  };

  useEffect(() => {
    if (!shifts) {
      dispatch(getShiftsData());
    }
    dispatch(getEmployeesData());
  }, []);

  const getFilteredShifts = (shifts) => {
    let shifts_filtered = shifts;
    shifts_filtered = !filters.pending
      ? (shifts_filtered = shifts_filtered.filter(
          (element) => element.shift_status !== 1
        ))
      : shifts_filtered;
    shifts_filtered = !filters.process
      ? shifts_filtered.filter((element) => element.shift_status !== 2)
      : shifts_filtered;
    shifts_filtered = !filters.ended
      ? shifts_filtered.filter((element) => element.shift_status !== 3)
      : shifts_filtered;
    return shifts_filtered;
  };

  return (
    <div>
      <div class="page-header row items-center justify-content-between no-gutters py-4 mb-0">
        <div className="d-block">
          <span className="text-uppercase page-subtitle">CALENDARIO</span>
          <h3 className="page-title">Turnos</h3>
        </div>
        <Link to="/shifts/add" className="btn btn-simply">
          Agregar turno
        </Link>
      </div>
      <div className="container-fluid">
        <div class="row mt-lg-5 mt-2">
          <div className="col-12 d-lg-flex px-0 px-lg-2">
            {calendaryOpen && (
              <>
                <div>
                  <Calendar onChange={handleChange} value={date} />
                  {/* <div className="d-flex items-center mt-3">
                    <input type="checkbox" name="waiting" id="waiting" />
                    <label className="ml-2 mb-0 text-xs" style={{lineHeight:"1"}} htmlFor="waiting">Mostrar en espera</label>
                  </div> */}
                </div>
                <div className="separator" />
              </>
            )}
            <div className="content-shifts w-full">
              <div className="d-lg-flex align-items-center justify-between w-full pr-lg-3 mb-3">
                <h4 className="text-xl font-semibold d-lg-flex align-items-center">
                  <img
                    onClick={() => setCalendaryOpen(!calendaryOpen)}
                    className="mr-2 mt-1"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAAD2ElEQVR4nO2azU8TQRjGn9kSrQ1UPnqAtV7kS70YUQFjNDEqyIdBReIBTTTKheiFm4nxYEz4DzxpghFNEFAD1YCKiSbGCGK8CYhGsSIkfFubgjDjobYsxO7OLi1j0vmdZtv3ffvM053ddzcDSCQSiUQiiVeImeCDZSe3KKB7GVhKrAStBAIyCYW+fNLe2sefw0HRkcrNlCrXCdh+6/JWEYLntnlS29HR3G8cakBRaWUBI6QTwPqoiFs9phRCijs9zd16QboGlJRUO+eV2T4AGQBgs9mwa8d2bHSrUBQlilpXDqUUQ97veNv7HgsLC6GPh9fZ5ja3tbX9jJSXoFf0tzJ3kfydfFJSIuqvXkZOdmb0VMeA/oFBXLpyDT7fLwBQA3TNBQD1keKN/sbjoUHN2dP//eQBIDcnC+fPngofM7Y4h3+hawABywmN83flRYy71diEqupzuH33HrfQ4R+jqKmtQ01tHX6MjHLn8eQW5u8MjxmQq1fL6AxIDA1SU5IjBt1/6MH09AxaHngMyi3y6vUbfB36hq9D3/Dqte51ynSuVisBkvRqReVKdqyiFHb7WhyrKOXO2bO7AGpGOtSMdOzZnW/q91aSuxzdu8ChshMsNH7iaV7RD602ReVV4fHTRy0R5/l/3csEoHsb1NLU8jCWOoTBbcDNhjux1CEMuQR4A0+eOBpLHVGHd8lyG3DuTLVlMSLgNSDul0DcG8C9BLR4x6bhHZsxjHO7nHC7Fl8jfByZ4qqfnb7Yyo77ApjwBQxzUhPtSEu0c9XXEvdngDRAtADRSANECxCNNEC0ANFIA0QLEI2lTtDtWr+kw+NF2+Hxkmaxw+Ml7s+AuDfA0hKY8c9ixm/8gOJ02OF0rA0fe8emueprl9fwlB/Dk37DHDXFATXZwVVfi0UDApxPg1hmgHFOME9jwKQfvV/GufKsGBD3S0AaIFqAaKQBogWIRhogWoBo4t4Ao0bIh7+7RCYmJpGaGtwf6XTY4XYZF3c6lj7EuF1O0wLVFL7mRhs3Nj6h/Uq3+9I1gIEMELA8AHjT8w4lxQcABLs7bYfHi5UnSDXZfIvb3fNOezigF2u0Sao1NL7R0Ij+gUFTQkTQ1/8RNxoaw8eMkVadcK6Nkh8AqEBwo+TOvG3IytwEu938GRBLAoFZDH76jJ7e96CUhj72rrPNbdXbKGm4Vba4vCqfMtYJwPzbDLFMUYUWdbXf79ELMrwLdHqau20LpBBAV9SkxRgC8owyW4HR5IOxJjh8uCp3PoHuU0A2MIbYvaeyACEIULDvjCa86HrcpHvhk0gkEolEIgGAP662FxmYxB3dAAAAAElFTkSuQmCC"
                    width={32}
                  />
                  {dateToString2(date)}
                  <div className="ml-lg-4 status d-flex">
                    <div
                      onClick={() =>
                        setFilters({ ...filters, pending: !filters.pending })
                      }
                      className={
                        "pending d-flex align-items-center " +
                        (!filters.pending && "disabled")
                      }
                    >
                      <span className="color d-block bg-yellow-300 w-3 h-3 mr-2"></span>
                      Pendiente
                    </div>
                    <div
                      onClick={() =>
                        setFilters({ ...filters, process: !filters.process })
                      }
                      className={
                        "progres d-flex align-items-center " +
                        (!filters.process && "disabled")
                      }
                    >
                      <span className="color d-block bg-blue-500 w-3 h-3 mr-2"></span>
                      En curso
                    </div>
                    <div
                      onClick={() =>
                        setFilters({ ...filters, ended: !filters.ended })
                      }
                      className={
                        "ended d-flex align-items-center " +
                        (!filters.ended && "disabled")
                      }
                    >
                      <span className="color d-block  bg-green-600 w-3 h-3 mr-2"></span>
                      Terminado
                    </div>
                  </div>
                </h4>
                {/* <h4 className="text-lg font-semibold my-2">
                  Ingresos del día{" "}
                  <span className="text-green-500 font-semibold">
                    +$
                    {Intl.NumberFormat("de-DE").format(
                      getTotalPriceDay(
                        shifts.filter((element) => !element.shift_waiting)
                      )
                    )}
                  </span>
                </h4> */}
              </div>
              {getFilteredShifts(shifts).length === 0 && !loading_date && (
                <>
                  <h4 className="text-sm font-normal">
                    No hay turnos registrados el día{" "}
                    <b>{dateToString2(date)}</b>
                  </h4>
                </>
              )}
              {loading_date &&
                [1, 2, 3, 4, 5, 6, 7, 8, 9].map((element) => <Loader />)}
              {!loading_date && (
                <div className="day-shifts mb-2">
                  {employees.map((employee) => (
                    <div className="">
                      <div className="mt-2 d-flex items-center">
                        <h4
                          data-bs-toggle="collapse"
                          href={"#collapseExample-" + employee.id.toString()}
                          role="button"
                          aria-expanded="false"
                          aria-controls={
                            "collapseExample-" + employee.id.toString()
                          }
                          className="collapsed collapse-button"
                        >
                          <span></span> Turnos de {employee.name}{" "}
                          {employee.lastname}{" "}
                          {`(${
                            shifts.filter(
                              (shift) =>
                                shift.employee_id.toString() ===
                                  employee.id.toString() &&
                                shift.shift_waiting === 0
                            ).length
                          })`}
                        </h4>
                        <div className="text-green-500 custom-font text-base mt-2 font-medium ml-2">
                          +$
                          {Intl.NumberFormat("de-DE").format(getTotalPriceDay(
                            shifts.filter(
                              (shift) =>
                                shift.employee_id.toString() ===
                                  employee.id.toString() &&
                                shift.shift_waiting === 0
                            )
                          ))}
                        </div>
                      </div>
                      <div
                        className="mb-3 collapse"
                        id={"collapseExample-" + employee.id.toString()}
                      >
                        {getFilteredShifts(
                          shifts.filter(
                            (shift) =>
                              shift.employee_id.toString() ===
                              employee.id.toString()
                          )
                        )
                          .filter((element) => !element.shift_waiting)
                          .sort(
                            (a, b) =>
                              getHours(a.shift_date, true) -
                              getHours(b.shift_date, true)
                          )
                          .map((shift, index) => (
                            <div
                              className={
                                "shift-sumary d-flex align-items-center justify-between  " +
                                (index % 2 !== 0 && "bg-white")
                              }
                            >
                              <div className="avatar d-flex align-items-center">
                                <div
                                  style={{
                                    border: `4px solid ${
                                      employees.find(
                                        (emp) =>
                                          emp.id.toString() ===
                                          shift.employee_id.toString()
                                      )?.color
                                    }`,
                                  }}
                                  className={
                                    (calendaryOpen ? "d-none" : "d-block") +
                                    " dog rounded-full overflow-hidden border-4 mr-2 "
                                    // +
                                    // (shift.shift_search
                                    //   ? " border-gray-500"
                                    //   : " border-gray-500")
                                  }
                                >
                                  <img
                                    src={
                                      shift.pet_image
                                        ? shift.pet_image
                                        : "https://png.pngtree.com/png-vector/20191027/ourlarge/pngtree-cute-pug-avatar-with-a-yellow-background-png-image_1873432.jpg"
                                    }
                                    style={{
                                      width: "35px",
                                      height: "35px",
                                      objectFit: "cover",
                                    }}
                                    width={35}
                                    height={35}
                                    alt={shift.pet_name}
                                  />
                                </div>
                                <div className="d-flex justify-content-start align-items-center">
                                  <span
                                    className={
                                      "color d-block rounded-full w-4 h-4 mr-2 " +
                                      (shift.shift_status === 1
                                        ? "bg-yellow-300"
                                        : shift.shift_status === 2
                                        ? "bg-blue-500"
                                        : shift.shift_status === 3
                                        ? "bg-green-600"
                                        : "bg-gray-600")
                                    }
                                  ></span>
                                  <h5
                                    data-toggle="modal"
                                    data-target={"#shift-" + shift.shift_id}
                                    className="ml-2 font-normal max-width-5 d-flex align-items-center min-width-5 cursor-pointer mobile-unset-width"
                                  >
                                    {shift.pet_name}({shift.client_name})
                                  </h5>
                                </div>
                              </div>
                              <p className="d-none d-lg-block text-center min-width-10 text-capitalize">
                                {shift.pet_race}
                              </p>
                              {/* <p className={"d-none d-lg-block p-1 rounded-2xl min-width-10 address text-white d-lg-block text-center px-3 py-1.5 text-sm font-bold " + (shift.shift_search ? ' bg-yellow-300 ' : ' bg-green-500 ')}>{shift.shift_search ? shift.client_address : 'Lo traen'}</p> */}
                              <p
                                className={
                                  "d-none d-lg-block p-1 rounded-2xl min-width-10 address text-white d-lg-block text-center px-3 py-1.5 text-sm font-bold " +
                                  (shift.shift_search
                                    ? "bg-gray-500 "
                                    : " bg-gray-500")
                                }
                              >
                                {shift.shift_search
                                  ? shift.client_address
                                  : "Lo traen"}
                              </p>
                              <p className="text-center min-width-5">
                                {getHours(shift.shift_date)}
                              </p>
                              <p className="d-none d-lg-block text-center min-width-12">
                                {shift.shift_cut_type !== "" &&
                                shift.shift_cut_type.toLowerCase() ===
                                  "sin corte"
                                  ? "Sin Corte"
                                  : "Corte(" + shift.shift_cut_type + ")"}{" "}
                                {shift.shift_cut_type && shift.shift_washed
                                  ? "/"
                                  : ""}{" "}
                                {shift.shift_washed ? "Baño" : ""}
                              </p>
                              {/* <p className="text-center">Estado</p> */}
                              <div className="d-flex align-items-center min-width-5 justify-content-between mobile-auto-width">
                                <p className="mr-5 d-lg-block d-none text-green-600 font-semibold">
                                  $
                                  {Intl.NumberFormat("de-DE").format(
                                    shift.shift_price
                                  )}
                                </p>
                                <button
                                  class="dropdown-toggle"
                                  type="button"
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                </button>
                                <ul
                                  class="dropdown-menu cursor-pointer"
                                  aria-labelledby="dropdownMenuButton1"
                                >
                                  <li>
                                    <a
                                      class="dropdown-item cursor-pointer"
                                      onClick={(e) =>
                                        setShiftStatus(shift, 1) +
                                        e.preventDefault
                                      }
                                    >
                                      Cambiar a pendiente
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      class="dropdown-item cursor-pointer"
                                      onClick={(e) =>
                                        setShiftStatus(shift, 2) +
                                        e.preventDefault
                                      }
                                    >
                                      Cambiar a en curso
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      class="dropdown-item cursor-pointer"
                                      onClick={(e) =>
                                        setShiftStatus(shift, 3) +
                                        e.preventDefault
                                      }
                                    >
                                      Finalizar
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      class="dropdown-item cursor-pointer"
                                      data-toggle="modal"
                                      data-target={"#shift-" + shift.shift_id}
                                      onClick={(e) => e.preventDefault}
                                    >
                                      Ver detalles
                                    </a>
                                  </li>
                                  <li>
                                    <Link
                                      className="dropdown-item cursor-pointer"
                                      to={"/shifts/update/" + shift.shift_id}
                                    >
                                      Editar
                                    </Link>
                                  </li>
                                  <li>
                                    <a
                                      class="dropdown-item cursor-pointer"
                                      onClick={(e) =>
                                        e.preventDefault +
                                        dispatch(deleteShift(shift.shift_id))
                                      }
                                    >
                                      Eliminar
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <Modal data={{ element: shift }} />
                            </div>
                          ))}
                        <h5
                          className={
                            getFilteredShifts(
                              shifts.filter(
                                (shift) =>
                                  shift.employee_id.toString() ===
                                  employee.id.toString()
                              )
                            ).length > 0 && "d-none"
                          }
                        >
                          No hay turnos registrados el día
                        </h5>
                      </div>
                    </div>
                  ))}
                  <h4
                    data-bs-toggle="collapse"
                    href={"#collapseExample-0"}
                    role="button"
                    aria-expanded="false"
                    aria-controls={"collapseExample-0"}
                    style={{ borderTop: "1px solid #ccc" }}
                    className="mt-3 collapsed border-gray-300 pt-3 collapse-button"
                  >
                    <div className="d-flex-items-center">
                      <span></span>
                      Todos los turnos del día{" "}
                      {`(${
                        getFilteredShifts(shifts).filter(
                          (element) => !element.shift_waiting
                        ).length
                      })`}
                      <div className="text-green-500 custom-font mt-1 ml-2 d-inline font-semibold">
                        +$
                        {Intl.NumberFormat("de-DE").format(
                          getTotalPriceDay(
                            shifts.filter((element) => !element.shift_waiting)
                          )
                        )}
                      </div>
                    </div>
                  </h4>
                  <div className="mb-5 collapse" id={"collapseExample-0"}>
                    {getFilteredShifts(shifts)
                      .filter((element) => !element.shift_waiting)
                      .sort(
                        (a, b) =>
                          getHours(a.shift_date, true) -
                          getHours(b.shift_date, true)
                      )
                      .map((shift, index) => (
                        <div
                          className={
                            "shift-sumary d-flex align-items-center justify-between " +
                            (index % 2 !== 0 && "bg-white")
                          }
                        >
                          <div className="avatar d-flex align-items-center">
                            <div
                              style={{
                                border: `4px solid ${
                                  employees.find(
                                    (emp) =>
                                      emp.id.toString() ===
                                      shift.employee_id.toString()
                                  )?.color
                                }`,
                              }}
                              className={
                                (calendaryOpen ? "d-none" : "d-block") +
                                " dog rounded-full overflow-hidden border-4 mr-2 "
                                //  +
                                // (shift.shift_search
                                //   ? " border-gray-500 "
                                //   : " border-gray-500")
                              }
                            >
                              <img
                                src={
                                  shift.pet_image
                                    ? shift.pet_image
                                    : "https://png.pngtree.com/png-vector/20191027/ourlarge/pngtree-cute-pug-avatar-with-a-yellow-background-png-image_1873432.jpg"
                                }
                                style={{
                                  width: "35px",
                                  height: "35px",
                                  objectFit: "cover",
                                }}
                                width={35}
                                height={35}
                                alt={shift.pet_name}
                              />
                            </div>
                            <div className="d-flex justify-content-start align-items-center">
                              <span
                                className={
                                  "color d-block rounded-full w-4 h-4 mr-2 " +
                                  (shift.shift_status === 1
                                    ? "bg-yellow-300"
                                    : shift.shift_status === 2
                                    ? "bg-blue-500"
                                    : shift.shift_status === 3
                                    ? "bg-green-600"
                                    : "bg-gray-600")
                                }
                              ></span>
                              <h5
                                data-toggle="modal"
                                data-target={"#shift-" + shift.shift_id}
                                className="ml-2 font-normal max-width-5 d-flex align-items-center min-width-5 cursor-pointer mobile-unset-width"
                              >
                                {shift.pet_name}({shift.client_name})
                              </h5>
                            </div>
                          </div>
                          <p className="d-none d-lg-block text-center min-width-10 text-capitalize">
                            {shift.pet_race}
                          </p>
                          {/* <p className={"d-none d-lg-block p-1 rounded-2xl min-width-10 address text-white d-lg-block text-center px-3 py-1.5 text-sm font-bold " + (shift.shift_search ? ' bg-yellow-300 ' : ' bg-green-500 ')}>{shift.shift_search ? shift.client_address : 'Lo traen'}</p> */}
                          <p
                            className={
                              "d-none d-lg-block p-1 rounded-2xl min-width-10 address text-white d-lg-block text-center px-3 py-1.5 text-sm font-bold " +
                              (shift.shift_search
                                ? "bg-gray-500 "
                                : " bg-gray-500")
                            }
                          >
                            {shift.shift_search
                              ? shift.client_address
                              : "Lo traen"}
                          </p>
                          <p className="text-center min-width-5">
                            {getHours(shift.shift_date)}
                          </p>
                          <p className="d-none d-lg-block text-center min-width-12">
                            {shift.shift_cut_type !== "" &&
                            shift.shift_cut_type.toLowerCase() === "sin corte"
                              ? "Sin Corte"
                              : "Corte(" + shift.shift_cut_type + ")"}{" "}
                            {shift.shift_cut_type && shift.shift_washed
                              ? "/"
                              : ""}{" "}
                            {shift.shift_washed ? "Baño" : ""}
                          </p>
                          {/* <p className="text-center">Estado</p> */}
                          <div className="d-flex align-items-center min-width-5 justify-content-between mobile-auto-width">
                            <p className="mr-5 d-lg-block d-none text-green-600 font-semibold">
                              $
                              {Intl.NumberFormat("de-DE").format(
                                shift.shift_price
                              )}
                            </p>
                            <button
                              class="dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <span></span>
                              <span></span>
                              <span></span>
                            </button>
                            <ul
                              class="dropdown-menu cursor-pointer"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li>
                                <a
                                  class="dropdown-item cursor-pointer"
                                  onClick={(e) =>
                                    setShiftStatus(shift, 1) + e.preventDefault
                                  }
                                >
                                  Cambiar a pendiente
                                </a>
                              </li>
                              <li>
                                <a
                                  class="dropdown-item cursor-pointer"
                                  onClick={(e) =>
                                    setShiftStatus(shift, 2) + e.preventDefault
                                  }
                                >
                                  Cambiar a en curso
                                </a>
                              </li>
                              <li>
                                <a
                                  class="dropdown-item cursor-pointer"
                                  onClick={(e) =>
                                    setShiftStatus(shift, 3) + e.preventDefault
                                  }
                                >
                                  Finalizar
                                </a>
                              </li>
                              <li>
                                <a
                                  class="dropdown-item cursor-pointer"
                                  data-toggle="modal"
                                  data-target={"#shift-" + shift.shift_id}
                                  onClick={(e) => e.preventDefault}
                                >
                                  Ver detalles
                                </a>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item cursor-pointer"
                                  to={"/shifts/update/" + shift.shift_id}
                                >
                                  Editar
                                </Link>
                              </li>
                              <li>
                                <a
                                  class="dropdown-item cursor-pointer"
                                  onClick={(e) =>
                                    e.preventDefault +
                                    dispatch(deleteShift(shift.shift_id))
                                  }
                                >
                                  Eliminar
                                </a>
                              </li>
                            </ul>
                          </div>
                          <Modal data={{ element: shift }} />
                        </div>
                      ))}
                    <h5
                      className={
                        getFilteredShifts(shifts).length > 0 && "d-none"
                      }
                    >
                      No hay turnos registrados el día
                    </h5>
                  </div>
                </div>
              )}
            </div>
            {/* <span>{date.toDateString()}</span> */}
          </div>
        </div>
      </div>

      <div className="col-12 mt-8 ">
        <h3 className="text-lg font-medium mb-2 mt-2">Turnos en espera</h3>
        {!loading && getWaitings(allShifts).length > 0 ? (
          <Shift date={1} shifts={getWaitings(allShifts)} isWaiting={true} />
        ) : (
          <span className="mt-2 d-block">No hay turnos en espera.</span>
        )}
      </div>

      <div className="col-12 mb-lg-4 ml-2 mt-5 underline">
        <Link className="" to={"/history"}>
          Ver historial de turnos
        </Link>
      </div>
    </div>
  );
};

export default listBlogs;
