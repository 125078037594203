import { Link } from "react-router-dom"
import { useEffect, useState } from "react";
import { useDispatch,useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import { getClientData,updateClientData } from "../../redux/reducers/clientsReducer";
import "react-datepicker/dist/react-datepicker.css";

const update_shifts = () => {

    const dispatch = useDispatch();
    const {id} = useParams();

    const response = useSelector((store) => store.clients.response);
    const loading = useSelector((store) => store.clients.loading);
    const cliente = useSelector((store) => store.clients.client);

    const [client,setClient] = useState({
        name:"",
        city:"",
        address:"",
        email:"",
        phone:"",
    })

    const handleChange = (e) => {
        setClient({
            ...client,
            [e.target.name]:e.target.value
        })
    }

    useEffect(() => {
        dispatch(getClientData(id))
    },[]);

    useEffect(()=> {
        console.log(cliente)
        setClient({
            name:cliente.name || "Sin definir",
            city:cliente.city || "Sin definir",
            address:cliente.address || "Sin definir",
            phone:cliente.phone || "Sin definir",
            email:cliente.email || "Sin definir",
        })
    },[cliente])

    const createClient = (e) => {
        e.preventDefault()
        dispatch(updateClientData(client,id))
    }


    return (
        <section className="add_shifts">
            <div class="page-header row items-center justify-content-between no-gutters py-4 mb-3">
                <div className="d-block">
                    <Link to="/clientes" className="underline color-simply">
                        Volver a clientes
                    </Link>
                    <span className="text-uppercase page-subtitle d-block mt-4">CLIENTES</span>
                    <h3 className="page-title">Actualizar cliente</h3>
                </div>
                {/* <button type="submit"  className="btn btn-simply">
                Agregar cliente
                </button> */}
            </div>
            <div className="row no-gutters pl-3 overflow-hidden align-items-stretch rounded-3xl">
                <div className="col-lg-8">
                    <form onSubmit={createClient} className="row align-items-center pr-5 pb-5 h-full" action="">
                        <div className="col-12 mb-3 position-relative">
                            <label className="d-block pl-3 w-100">Nombre del cliente</label>
                            <input type="text" required onChange={(e) => handleChange(e)} value={loading ? "Cargando..." : client.name} name="name" id="" />
                        </div>
                        <div className="col-6">
                            <label className="d-block pl-3 w-100">Localidad</label>
                            <input type="text" onChange={(e) => handleChange(e)} name="city" value={loading ? "Cargando..." : client.city}  placeholder="Temperley" id="" />
                        </div>
                        <div className="col-6">
                            <label className="d-block pl-3 w-100">Dirección</label>
                            <input type="text" onChange={(e) => handleChange(e)} name="address" value={loading ? "Cargando..." : client.address}  placeholder="Charcas 259" id="" />
                        </div>
                        <div className="col-6">
                            <label className="d-block mt-4 pl-3 w-100">Teléfono</label>
                            <input type="text" onChange={(e) => handleChange(e)} name="phone" value={loading ? "Cargando..." : client.phone}  placeholder="11-1111-1111" id="" />
                        </div>
                        <div className="col-6">
                            <label className="d-block mt-4 pl-3 w-100">Email</label>
                            <input type="text" onChange={(e) => handleChange(e)} name="email" value={loading ? "Cargando..." : client.email}  placeholder="email@gmail.com" id="" />
                        </div>
                        <div className="col-12 mt-4 d-flex align-items-center justify-content-lg-end">
                            <button type="submit"  className="btn btn-simply">
                                Actualizar cliente
                            </button>

                        </div>
                    </form>
                </div>
                <div className="col-4 d-none d-lg-block">
                    <img className="w-100 img-responsive h-full object-cover object-center" src="/images/form.jpg" alt="" />
                </div>
            </div>
        </section>
    )
}

export default update_shifts