import { useDispatch, useSelector } from "react-redux"

import toast, { Toaster } from 'react-hot-toast';

import {clearClientStatus} from '../redux/reducers/clientsReducer'
import {clearPetsStatus} from '../redux/reducers/petsReducer'
import {clearStatusAction} from '../redux/reducers/shiftsReducer'


import { useEffect} from "react"

const Toast_Response = () => {

    const dispatch = useDispatch();

    const response_clients = useSelector((store) => store.clients.response);
    const response_pets = useSelector((store) => store.pets.response);
    const response_shifts = useSelector((store) => store.shifts.response);

    useEffect(() => {
        if(response_clients.status){
            if(response_clients.success){
                if(response_clients.statusText !== "" && response_clients.statusText !== " "){
                    toast.success(response_clients.statusText, { duration: 4000, position: 'top-center'})
                }
            }
            else{
                if(response_clients.statusText.length > 1){
                    if(response_clients.statusText !== "" && response_clients.statusText !== " "){
                        for (const iterator of response_clients.statusText) {
                            toast.error(iterator, { duration: 4000, position: 'top-center'})
                        }
                    }
                }
                else{
                    toast.error(response_clients.statusText, { duration: 4000, position: 'top-center'})
                }
            }
            setTimeout(function(){dispatch(clearClientStatus()) }, 1000);
        }
    },[response_clients])
    useEffect(() => {
        if(response_pets.status){
            if(response_pets.success){
                toast.success(response_pets.statusText, { duration: 4000, position: 'top-center'})
            }
            else{
                if(response_pets.statusText.length > 1){
                    for (const iterator of response_pets.statusText) {
                        toast.error(iterator, { duration: 4000, position: 'top-center'})
                    }
                }
                else{
                    toast.error(response_pets.statusText, { duration: 4000, position: 'top-center'})
                }
            }
            setTimeout(function(){dispatch(clearPetsStatus()) }, 1000);
        }
    },[response_pets])
    useEffect(() => {
        if(response_shifts.status){
            if(response_shifts.success){
                toast.success(response_shifts.statusText, { duration: 4000, position: 'top-center'})
            }
            else{
                if(response_shifts.statusText.length > 1){
                    for (const iterator of response_shifts.statusText) {
                        toast.error(iterator, { duration: 4000, position: 'top-center'})
                    }
                }
                else{
                    toast.error(response_pets.statusText, { duration: 4000, position: 'top-center'})
                }
            }
            setTimeout(function(){dispatch(clearStatusAction()) }, 1000);
        }
    },[response_shifts])

    return response_clients || response_pets || response_shifts ? (
    <>
        <Toaster />
    </>
    ):''
}

export default Toast_Response
