import DatePicker, { registerLocale } from "react-datepicker";
import { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import "react-datepicker/dist/react-datepicker.css";
import { SketchPicker } from "react-color";
import reactCSS from "reactcss";
import es from "date-fns/locale/es";
import {
  addEmployeeData,
  getEmployeesData,
  setEmployeeAction,
  updateEmployeeData,
} from "../../redux/reducers/employeesReducer";
import toast from "react-hot-toast";
registerLocale("es", es);

const add_employee = () => {
  const [employee, setEmployee] = useState({
    name: "",
    lastname: "",
    gender: "Sin Especificar",
    date_hiring: "Sin Especificar",
    address: "Sin Especificar",
    phone: "Sin Especificar",
    position: "Sin Especificar",
    description: "Sin Especificar",
    color: colorHEX(),
  });

  const { employees, employee: employeeSelected } = useSelector(
    (store) => store.employees
  );
  const dispatch = useDispatch();
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleChange = (e) => {
    setEmployee({
      ...employee,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeColor = (e) => {
    setEmployee({ ...employee, color: e.hex });
  };

  const createEmployee = (e) => {
    e.preventDefault();
    if (employeeSelected?.id) {
      dispatch(updateEmployeeData(employee));
      toast.success("Empleado actualizado correctamente.", {
        duration: 2000,
        position: "top-center",
      });
    } else {
      dispatch(addEmployeeData(employee));
      toast.success("Empleado creado correctamente.", {
        duration: 2000,
        position: "top-center",
      });
    }
    setEmployee({
      name: "",
      lastname: "",
      gender: "Sin Especificar",
      date_hiring: "Sin Especificar",
      address: "Sin Especificar",
      phone: "Sin Especificar",
      position: "Sin Especificar",
      description: "Sin Especificar",
    });
    document.getElementById("formCreateEmployee").reset();
  };

  useEffect(() => {
    dispatch(getEmployeesData());
  }, []);

  useEffect(() => {
    if (employeeSelected?.id) {
      setEmployee(employeeSelected);
    } else {
      setEmployee({
        name: "",
        lastname: "",
        gender: "Sin Especificar",
        date_hiring: "Sin Especificar",
        address: "Sin Especificar",
        phone: "Sin Especificar",
        position: "Sin Especificar",
        description: "Sin Especificar",
      });
    }
  }, [employeeSelected]);

  function generarLetra() {
    var letras = [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
    ];
    var numero = (Math.random() * 15).toFixed(0);
    return letras[numero];
  }

  function colorHEX() {
    var coolor = "";
    for (var i = 0; i < 6; i++) {
      coolor = coolor + generarLetra();
    }
    return "#" + coolor;
  }

  console.log(employee);

  return (
    <div
      class="modal fade"
      id="employeeModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered shift_modal"
        role="document"
      >
        <div class="modal-content">
          <div className="modal-header d-block d-lg-none">
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setEmployeeAction({})}
            >
              <span onClick={() => setEmployeeAction({})} aria-hidden="true">
                &times;
              </span>
            </button>
          </div>
          <div class="modal-body">
            <section className="add_shifts">
              <div className="row no-gutters overflow-hidden align-items-stretch rounded-3xl">
                <div className="col-12 d-none d-lg-block">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setEmployeeAction({})}
                  >
                    <span
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      aria-hidden="true"
                      onClick={() => setEmployeeAction({})}
                    >
                      &times;
                    </span>
                  </button>
                  <img
                    className="w-100 img-responsive object-cover object-center"
                    src="/images/form.jpg"
                    style={{ height: "40vh" }}
                  />
                </div>
                <div className="col-lg-12">
                  <form
                    id="formCreateEmployee"
                    className="row align-items-center pr-5 h-full"
                    onSubmit={createEmployee}
                  >
                    <div className="col-lg-5 mb-3 mt-lg-3 mt-2 position-relative">
                      <label className="d-block pl-3 w-100">Nombre</label>
                      <input
                        type="text"
                        required
                        onChange={handleChange}
                        name="name"
                        placeholder="Axel"
                        value={employee.name}
                      />
                    </div>
                    <div className="col-lg-5 mb-3 mt-lg-3 mt-2 position-relative">
                      <label className="d-block pl-3 w-100">Apellido</label>
                      <input
                        type="text"
                        required
                        onChange={handleChange}
                        name="lastname"
                        placeholder="Recúpero"
                        value={employee.lastname}
                      />
                    </div>
                    <div className="col-lg-2 mb-3 mt-lg-3 mt-2 position-relative">
                      <label className="d-block w-100">Color</label>
                      <div
                        style={{
                          background: `${employee.color}`,
                          height: "50px",
                          width: "70px",
                          borderRadius: "5px",
                        }}
                        onClick={() =>
                          setDisplayColorPicker(!displayColorPicker)
                        }
                      ></div>
                      {displayColorPicker ? (
                        <div className="container-position-picker" style={{ position: "absolute", zIndex: "2" }}>
                          <div
                            style={{
                              position: "fixed",
                              top: "0px",
                              right: "0px",
                              bottom: "0px",
                              left: "0px",
                            }}
                            onClick={() => setDisplayColorPicker(!displayColorPicker)}
                          />
                          <SketchPicker
                            color={employee.color}
                            onChange={handleChangeColor}
                          />
                        </div>
                      ) : // <div
                      //   style={{
                      //     position: "absolute",
                      //     bottom: "0px",
                      //     right: "200px",
                      //   }}
                      // >

                      //   <SketchPicker
                      //     color={employee.color}
                      //     onChange={handleChangeColor}
                      //   />
                      // </div>
                      null}
                    </div>
                    <div className="col-12 mt-lg-0 mt-3">
                      <button
                        type="submit"
                        data-dismiss="modal"
                        className="btn btn-simply"
                      >
                        {`${employeeSelected?.id ? "Editar" : "Agregar"}`}{" "}
                        empleado
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default add_employee;
