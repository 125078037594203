import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUserData, updateUserData } from "../../redux/reducers/userReducer";
import { useMercadopago } from "react-sdk-mercadopago";
import {
  deleteEmployee,
  getEmployeesData,
  setEmployeeAction,
} from "../../redux/reducers/employeesReducer";
import ModalAddEmployee from "../modals/add_employee_modal";

const Main = () => {
  const dispatch = useDispatch();
  const userData = useSelector((store) => store.users.userStats).data;
  const { employees, loading } = useSelector((store) => store.employees);

  const [loadingImage, setLoadingImage] = useState(false);

  const picture_profile = useRef();

  const [curStatsUser, setCurStats] = useState();
  const [imgToUpload, setImgToUpload] = useState();
  const [drag, setDrag] = useState(false);

  const Roles = [
    {
      id: 0,
      name: "Administrador",
    },
  ];

  const handleInput = (event) => {
    setCurStats({ ...curStatsUser, [event.target.name]: event.target.value });
  };

  const changeFile = () => {
    const image = picture_profile.current.files[0];
    setImgToUpload(image);
    setCurStats({ ...curStatsUser, image: picture_profile.current.files[0] });
    loadImage(image);
    dispatch(updateUserData(curStatsUser, image));
  };

  const sendData = (event) => {
    event.preventDefault();
    dispatch(updateUserData(curStatsUser, imgToUpload));
  };

  const onDragEnter = (event) => {
    event.preventDefault();
    setDrag(true);
  };

  const onDragLeave = (e) => {
    e.preventDefault();
    setDrag(false);
  };

  const onDrop = (event) => {
    // Drag and drop
    event.preventDefault();
    event.stopPropagation();

    const file = event.dataTransfer.files;
    picture_profile.current.files = file;
  };

  const loadImage = (file) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      setCurStats({ ...curStatsUser, picture_profile: e.target.result });
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    dispatch(getEmployeesData());
  }, []);

  useEffect(() => {
    setCurStats(userData.user);
    console.log(userData);
  }, [userData]);

  // // const mercadopago_1 = useMercadopago.v2('APP_USR-564e6501-7a36-4c45-aa82-815a117cd911', {
  // const mercadopago_1 = useMercadopago.v2(
  //   "TEST-c35bf15d-d030-42ca-b1cc-efc263bd374c",
  //   {
  //     locale: "en-US",
  //   }
  // );
  // // const mercadopago_2 = useMercadopago.v2('APP_USR-564e6501-7a36-4c45-aa82-815a117cd911', {
  // const mercadopago_2 = useMercadopago.v2(
  //   "TEST-c35bf15d-d030-42ca-b1cc-efc263bd374c",
  //   {
  //     locale: "en-US",
  //   }
  // );

  // useEffect(() => {
  //   if (mercadopago_1) {
  //     mercadopago_1.checkout({
  //       preference: {
  //         id: "1208790816-d70df6c8-f905-4f47-a9c3-8f97ca754778",
  //       },
  //       render: {
  //         container: ".cho-container-1",
  //         label: "Acreditar un mes",
  //       },
  //     });
  //   }
  // }, [mercadopago_1]);
  // useEffect(() => {
  //   if (mercadopago_2) {
  //     mercadopago_2.checkout({
  //       preference: {
  //         id: "1208790816-d70df6c8-f905-4f47-a9c3-8f97ca754778",
  //       },
  //       render: {
  //         container: ".cho-container-2",
  //         label: "Acreditar un año",
  //       },
  //     });
  //   }
  // }, [mercadopago_2]);

  return curStatsUser ? (
    <div className="container-fluid section-settings">
      <ModalAddEmployee />
      <div class="page-header row no-gutters py-4">
        <div class="col-12 col-sm-4 text-center text-sm-left mb-0">
          <span class="text-uppercase page-subtitle">Panel</span>
          <h3 class="page-title">Perfil del negocio</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <div class="card card-small mb-4 pt-3">
            <div class="card-header border-bottom text-center">
              <div class="mb-3 mx-auto content-img">
                <img
                  class="rounded-circle"
                  src={
                    curStatsUser.picture_profile > 0 &&
                    curStatsUser.picture_profile[0] == "a"
                      ? "https://api.mediahaus.com.ar/"
                      : "" + curStatsUser.picture_profile
                  }
                  alt={curStatsUser.name}
                  width="110"
                />{" "}
                <div
                  className={"drag mb-4" + drag}
                  dragleave={onDragLeave}
                  dragenter={onDragEnter}
                  onDrop={onDrop}
                >
                  <label for="Addfile" class="labelFile">
                    {/* <i class="fas fa-camera"></i> */}
                  </label>
                  <input
                    className="d-none"
                    type="file"
                    name="addFile"
                    ref={picture_profile}
                    onChange={changeFile}
                    id="Addfile"
                  />
                </div>
              </div>
              <h4 class="mb-0">{curStatsUser.name}</h4>
              <span class="text-muted d-block mb-2">
                {Roles[curStatsUser.rol].name}
              </span>
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item px-4">
                <div class="progress-wrapper">
                  <strong class="text-muted d-block mb-2">Proceso</strong>
                  <div class="progress progress-sm">
                    <div
                      class="progress-bar bg-primary"
                      role="progressbar"
                      aria-valuenow="74"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: 100 + "%" }}
                    >
                      <span class="progress-value">100%</span>
                    </div>
                  </div>
                </div>
              </li>
              <li class="list-group-item p-4">
                <strong class="text-muted d-block mb-2">Descripcion</strong>
                <span>{curStatsUser.description}</span>
              </li>
              <li class="list-group-item p-4">
                <strong class="text-muted d-flex mb-2 justify-content-between align-center align-middle items-center">
                  Empleados{" "}
                  <button
                    onClick={() => dispatch(setEmployeeAction({ id: "" }))}
                    data-bs-toggle="modal"
                    data-bs-target="#employeeModal"
                    className="text-white text-2xl flex items-center justify-center border-white w-7 h-7 bg-green-600 border-2 rounded-full"
                  >
                    +
                  </button>
                </strong>
                <div className="d-flex flex-column">
                  {employees.map((employe) => (
                    <span className="flex items-center">
                      <button
                        className="d-none mr-3 text-white flex items-center justify-center border-white w-6 text-xs h-6 bg-red-600 border-2 rounded-full delete-button-employee"
                        onClick={() => dispatch(deleteEmployee(employe.id))}
                      >
                        {" "}
                        <i></i>
                        <i></i>{" "}
                      </button>{" "}
                      <div
                        style={{
                          height: "15px",
                          width: "15px",
                          borderRadius: "3px",
                          background: `${employe.color}`,
                        }}
                      ></div>{" "}
                      <div
                      className="ml-3"
                        data-bs-toggle="modal"
                        data-bs-target="#employeeModal"
                        onClick={() => dispatch(setEmployeeAction(employe))}
                      >
                        {" "}
                        {`${employe.name} ${employe.lastname}`}
                      </div>{" "}
                    </span>
                  ))}
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="card card-small mb-4">
            <div class="card-header border-bottom">
              <h6 class="m-0">Detalles de la cuenta</h6>
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item p-3">
                <div class="row">
                  <div class="col">
                    <form onSubmit={sendData}>
                      <div class="form-row">
                        <div class="form-group col-md-6">
                          <label for="feFirstName">Nombre</label>
                          <input
                            type="text"
                            class="form-control"
                            id="feFirstName"
                            name="name"
                            placeholder="First Name"
                            value={curStatsUser.name}
                            onChange={handleInput}
                          />
                        </div>
                        <div class="form-group col-md-6">
                          <label for="feLastName">Instagram</label>
                          <input
                            type="text"
                            class="form-control"
                            id="feLastName"
                            name="lastname"
                            placeholder="https://www.instagram.com/tuinstagram"
                            value={curStatsUser.lastname}
                            onChange={handleInput}
                          />
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col-md-12">
                          <label for="feEmailAddress">Email</label>
                          <input
                            type="email"
                            class="form-control"
                            id="feEmailAddress"
                            name="email"
                            placeholder="Email"
                            disabled
                            value={curStatsUser.email}
                            onChange={handleInput}
                          />
                        </div>
                        {/* <div class="form-group col-md-6">
                          <label for="fePassword">Password</label>
                          <input
                            type="password"
                            class="form-control"
                            id="fePassword"
                            name="password"
                            value={curStatsUser.password}
                            onChange={handleInput}
                            disabled
                          />
                        </div> */}
                      </div>
                      <div class="form-row">
                        <div class="form-group col-md-6">
                          <label for="feInputAddress">Rol</label>
                          <input
                            type="text"
                            class="form-control"
                            id="feInputAddress"
                            disabled
                            name="rol"
                            value={Roles[curStatsUser.rol].name}
                            onChange={handleInput}
                          />
                        </div>
                        <div class="form-group col-md-6">
                          <label for="feInputCity">Ciudad</label>
                          <input
                            type="text"
                            class="form-control"
                            id="feInputCity"
                            name="city"
                            value={curStatsUser.city}
                            onChange={handleInput}
                          />
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col-md-12">
                          <label for="feDescription">Descripción</label>
                          <div className="content-input form-control descriptionInput">
                            <input
                              className="w-100"
                              name="description"
                              value={curStatsUser.description}
                              onChange={handleInput}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-between">
                        <button type="submit" class="btn btn-accent">
                          Actualizar cuenta
                        </button>
                        {/* <div class="cho-container-1" />
                        <div class="cho-container-2" /> */}
                      </div>
                    </form>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
};

export default Main;
