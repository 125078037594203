import { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../redux/reducers/userReducer";
import { getBlogData } from "../redux/reducers/blogReducer";


import Header from "../components/header.jsx";
import Sidebar from "../components/sidebar.jsx";

import Panel from "../components/pets/add_pets"

const Blog = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const loading = useSelector((store) => store.users.loading);
    const logged = useSelector((store) => store.users.logged);
    const response = useSelector((store) => store.pets.response);


    useEffect(() => {
      // dispatch(getUserData());
      dispatch(getBlogData());
      if(!localStorage.getItem("Api_Token")){
        history.push("/login")
      }
      if(!loading){
        if(!logged){
          history.push("/login")
        }
      }
    }, [loading]);

    useEffect(() => {
      if(response.status){
        if(response.status === 200){
          // history.push("/shifts")
        }
      }
    } , [response]);

  return !loading ? (
    <div>
      <Header />
      <Sidebar />
      <div class="main-content-container container-fluid px-4">
        <Panel />
      </div>
    </div>
  ):'';
};

export default Blog;
