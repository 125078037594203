import { useEffect, useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { loginUser } from "../redux/reducers/userReducer";
import { getUserData } from "../redux/reducers/userReducer";

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userData = useSelector((store) => store.users.userStats)?.data;
  const logged = useSelector((store) => store.users.logged);
  const loading = useSelector((store) => store.users.loading);
  const errorMessage = useSelector((store) => store.users.errorMessage);

  const inputEmail = useRef();
  const inputPassword = useRef();

  const [buttonPress, setButtonPress] = useState(false);

  useEffect(() => {
    if(errorMessage.data === '' || errorMessage === ''){
      dispatch(getUserData());
    }
  }, [errorMessage]);

  useEffect(() => {
    if (localStorage.getItem("Api_Token") != "") {
      if (userData && logged) {
        history.push("/");
      }
    }
  }, [userData,logged]);
  
  const checkLogin = () => {
    if (logged) {
      history.push("/");
    }
  }

  return (
    <section class="login-block d-flex align-items-center animate__animated animate__fadeIn animate__slower">
      {loading ? (
        <section className="loading_section">
          <div class="sk-chase">
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
          </div>
        </section>
      ) : (
        ""
      )}
      <div class="container">
        <div
          class="modal fade"
          id="modalPassword"
          tabindex="-1"
          role="dialog"
          aria-labelledby="modalPassword"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="modalPassword">
                  Modal title
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">...</div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary">
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row d-flex align-items-center">
          <div class="col-md-4 login-sec mt-5">
            <form class="login-form">
              <div class="form-group">
                <label for="exampleInputEmail1" class="">
                  E-mail
                </label>
                <input
                  ref={inputEmail}
                  type="text"
                  class="form-control"
                  placeholder=""
                />
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1" class="">
                  Contraseña
                </label>
                <input
                  ref={inputPassword}
                  type="password"
                  class="form-control"
                  placeholder=""
                />
              </div>
              <div class="form-check">
                <label class="form-check-label">
                  <input type="checkbox" class="form-check-input" />
                  <small>Recordarme</small>
                </label>
                <span
                  onClick={() =>
                    dispatch(
                      loginUser(
                        inputEmail.current.value,
                        inputPassword.current.value
                      )
                    ) + setButtonPress(true) + checkLogin()
                  }
                  class="btn btn-login float-right"
                >
                  Ingresar
                </span>
              </div>
            </form>
                <span className={"text-danger text-error d-block mt-5 " + (errorMessage == 403 && buttonPress ? "" : 'opacity-none')}> <i class="fas fa-times-circle"></i> Correo o contraseña incorrecta </span>
          </div>
          <div class="col-md-8 banner-sec">
            <div
              id="carouselExampleIndicators"
              class="carousel slide"
              data-ride="carousel"
            >
              <div class="carousel-inner" role="listbox">
                <div class="carousel-item active"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
