import { Link } from "react-router-dom"
import DatePicker, {registerLocale } from "react-datepicker";
import { useState, useEffect } from "react";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

import { useSelector, useDispatch } from "react-redux";

import "react-datepicker/dist/react-datepicker.css";
import { getPetsData } from "../../redux/reducers/petsReducer";
import { updateShiftData, getShiftData, getShiftsData } from "../../redux/reducers/shiftsReducer";

import {useParams} from "react-router-dom";

import es from "date-fns/locale/es";
registerLocale("es", es);


const update_Shift = () => {

    const {id} = useParams();
    const [startDate, setStartDate] = useState(new Date());
    const [shift,setShift] = useState({
        pet_id: "",
        cut_type: "",
        price:"",
        washed:false,
        fragrance:false,
        date:"",
        description:"",
        search:false,
        waiting:false,
    });
    const pets = useSelector((store) => store.pets.pets);
    const shifts = useSelector((store) => store.shifts.shifts);
    const cur_shift = useSelector((store) => store.shifts.shift);
    const dispatch = useDispatch();

    const formateDate = (date) => {
        //formate date to dd/mm/yyyy hh:mm
        let day = date.getDate();
        if(day < 10) day = "0" + day;
        let month = date.getMonth() + 1;
        if(month < 10){month = "0" + month}
        let year = date.getFullYear();
        let hours = date.getHours();
        if(hours < 10){hours = "0" + hours}
        let minutes = date.getMinutes();
        if(minutes < 10){minutes = "0" + minutes}
        const response =  day + "-" + month + "-" + year + " " + hours + ":" + minutes;
        return response;
    }
    const handleOnSelect = (item) => {
        setShift({...shift, pet_id: item.id})
    }
    const handleChange = (e) => {
        if(e.target.name === 'fragrance' || e.target.name === 'search'){
            setShift({...shift, [e.target.name]: e.target.checked})
        }
        else if(e.target.name === 'washed'){
            if(e.target.value === 'true' || e.target.value === '1'){
                setShift({...shift, [e.target.name]: '1'})
            }
            else{
                setShift({...shift, [e.target.name]: '0'})
            }
        }
        else{
            setShift({
                ...shift,
                [e.target.name]:e.target.value
            })
        }
    }
    const createShift = (e) => {
        e.preventDefault()
        dispatch(updateShiftData(shift))
        setShift({...shift,
            pet_id: "",
            cut_type: "",
            price:"",
            washed:false,
            fragrance:false,
            description:"",
            search:false
        })
        handleChange({"target":{"name":"date","value":formateDate(startDate)}})
        document.getElementById("formCreateShift").reset();
    }

    useEffect(() => {
        dispatch(getPetsData());
        dispatch(getShiftData(id));
        if(shifts.length > 0){
            dispatch(getShiftsData());
        }
    } ,[]);

    //Functio for Convert string to date from "DD-MM-YYYY HH:MM" to Date
    const convertStringToDate = (date) => {
        if(date){
            let date_array = date.split(" ");
            let date_array_date = date_array[0].split("-");
            let date_array_time = date_array[1].split(":");
            let date_object = new Date(date_array_date[2],date_array_date[1]-1,date_array_date[0],date_array_time[0],date_array_time[1]);
            return date_object;
        }
    }

    useEffect(() => {
        setShift({
            id:cur_shift.id,
            pet_id: cur_shift.pet_id,
            cut_type: cur_shift.cut_type,
            price:cur_shift.price,
            washed:cur_shift.washed,
            fragrance:cur_shift.fragrance,
            date:cur_shift.date,
            description:cur_shift.description,
            search:cur_shift.search,
            waiting:cur_shift.waiting
        })
        setStartDate(convertStringToDate(cur_shift.date))
        // handleChange({"target":{"name":"date","value":formateDate(startDate)}})
        // console.log(convertStringToDate(cur_shift.date));

    } ,[cur_shift]);
    
    useEffect(() => {
        if(shift.date){
            handleChange({"target":{"name":"date","value":formateDate(startDate)}})
        }
        // handleChange({"target":{"name":"date","value":formateDate(startDate)}})
    } ,[startDate]);
    

    return (
        <section className="add_shifts">
            <div class="page-header row items-center justify-content-between no-gutters py-4 mb-3">
                <div className="d-block">
                    <span className="text-uppercase page-subtitle">CALENDARIO</span>
                    <h3 className="page-title">Agregar nuevo turno</h3>
                </div>
                {/* <button type="submit" className="btn btn-simply">
                Agregar turno
                </button> */}
            </div>
            <div className="row no-gutters pl-lg-3 overflow-hidden align-items-stretch rounded-3xl">
                <div className="col-lg-8">
                    <form id="formCreateShift" className="row align-items-center pr-lg-5 h-full" onSubmit={createShift}>
                        <div className="col-12 mb-3 position-relative">
                            <label className="d-block pl-3 w-100">Nombre</label>
                            {/* {console.log(pets,shift)} */}
                            <input type="text" disabled="true" value={pets.find(element => element?.id?.toString() === shift?.pet_id?.toString())?.name} id="" />
                            {/* <Link to="/pets/add" className="text-green-500 btn-pet_add font-medium position-absolute flex items-center h-100 justify-content-center text-end text-xs top-0" style={{marginTop:".9rem", right:'4rem'}}>Agregar nueva mascota</Link> */}
                        </div>
                        <div className="col-lg-8">
                            <label className="d-block pl-3 w-100">Corte</label>
                            <select onChange={(e) => handleChange(e)} value={shift.cut_type} name="cut_type" className="w-100">
                                <option value="">Seleccionar una opción</option>
                                <option value="sin corte">Sin corte</option>
                                <option value="raza">Raza</option>
                                <option value="pelado">Pelado</option>
                                <option value="cm">1 Centimetro</option>
                                <option value="personalizado">Personalizado</option>
                            </select>
                        </div>
                        <div className="col-lg-4">
                            <label className="d-block pl-3 w-100" >Precio</label>
                            <input required value={shift.price} onChange={(e) => handleChange(e)} name="price" className="w-100" type="text" />
                        </div>
                        <div className="col-lg-8 mt-3">
                            <label className="d-block pl-3 w-100">Fecha/Horario</label>
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                locale={es}
                                timeCaption="time"
                                dateFormat="MMMM d, yyyy h:mm aa"
                            />
                        </div>
                        <div className="col-lg-4 mt-3">
                            <label className="d-block pl-3 w-100">Baño</label>
                            <select onChange={(e) => handleChange(e)} value={shift.washed} name="washed" className="w-100">
                                <option value="">Seleccionar una opción</option>
                                <option value={"1"}>Si</option>
                                <option value={"0"}>No</option>
                            </select>
                        </div>
                        <div className="col-12 mt-3">
                            <textarea name="description" value={shift.description} onChange={(e) => handleChange(e)} id="" cols="30" rows="10" placeholder="Información extra no obligatoria sobre el turno.."></textarea>
                        </div>
                        <div className="col-12 mt-3">
                            <input type="checkbox" checked={shift.search} onChange={(e) => handleChange(e)} className="mr-2" name="search" id="" />
                            <label for="search" className="mr-5">Hay que buscarlo</label>
                            <input type="checkbox" checked={shift.fragrance}  onChange={(e) => handleChange(e)} className="mr-2" name="fragrance" id="" />
                            <label for="fragrance"  className="mr-4">Sin perfume</label>
                        </div>
                        <div className="col-12 mt-3">
                            <button onClick={() => setShift({...shift, waiting: false})} type="submit" className="btn btn-simply">
                                Actualizar turno
                            </button>
                            <button onClick={() => setShift({...shift, waiting: true})} type="submit" className="btn btn-warning ml-2">
                                Actualizar turno en espera
                            </button>
                        </div>
                    </form>
                </div>
                <div className="col-4 d-none d-lg-block">
                    <img className="w-100 img-responsive h-full object-cover object-center" src="/images/form.jpg" alt="" />
                </div>
            </div>
        </section>
    )
}

export default update_Shift