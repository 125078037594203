import axios from "axios";
import "../../config";
// constantes
const dataInicial = {
  clients: [],
  client:{},
  loading: false,
  loading_create: false,
  response: {}
};

// types
const GET_CLIENTS = "GET_CLIENTS";
const GET_CLIENT = "GET_CLIENT";
const ADD_CLIENT = "ADD_CLIENT";
const DELETE_CLIENT = "DELETE_CLIENT";
const ADD_BLOG_FAILED = "ADD_BLOG_FAILED";
const UPDATE_CLIENT = "UPDATE_CLIENT";
const LOADING_CLIENTS = "LOADING_CLIENTS";
const LOADING_CREATE = "LOADING_CREATE";
const ERROR_CREATE_CLIENT = "ERROR_CREATE_CLIENT";
const CLEAR_STATUS_ACTION = "CLEAR_STATUS_ACTION";

// reducer
export default function dataReducer(state = dataInicial, action) {
  switch (action.type) {
    case LOADING_CLIENTS:
      return { ...state, loading: true };
    case LOADING_CREATE:
      return { ...state, loading: true };
    case GET_CLIENTS:
      return { ...state, 
        clients: action.payload, 
        loading:false
      };
    case GET_CLIENT:
      return { ...state, 
        client: action.payload, 
        loading:false
      };
    case ADD_CLIENT:
      return {
        ...state,
        clients: [...state.clients, action.payload],
        loading: false,
        response:{
          status:200,
          success:true,
          statusText:"El cliente fue creado correctamente."
        }
      };
    case DELETE_CLIENT:
      return {
        ...state,
        clients: [...state.clients.filter((client) => client.id.toString() !== action.payload.id.toString())],                
        loading: false,
        response:{
          status:200,
          success:true,
          statusText:"El cliente fue eliminado correctamente."
        }
      };
      case UPDATE_CLIENT:
        return {
          ...state,
          // clients: [
          //   ...state.clients.filter((element) => element.id != action.payload.id),
          //   action.payload,
          // ],
          response:{
            status:200,
            success:true,
            statusText:"El cliente fue actualizado correctamente."
          },
          loading: false,
      };
    case ERROR_CREATE_CLIENT:
      return {
        ...state,
        response:{
          status:400,
          message:"Ocurrio un error al crear el cliente."
        }
      };
    case CLEAR_STATUS_ACTION:
      return{
        ...state,
        response:{}
    };
    default:
      return state;
  }
}

// actions
export const addClientData = (client) => async (dispatch, getState) => {
  dispatch({
    type: LOADING_CLIENTS,
  });
  var dataSend = new FormData();
  dataSend.append("name", client.name ? client.name : "Sin definir");
  dataSend.append("address", client.address ? client.address : "Sin definir");
  dataSend.append("city", client.city ? client.city : "Sin definir");
  dataSend.append("email", client.email ? client.email : "Sin definir");
  dataSend.append("phone", client.phone ? client.phone : "Sin definir");
  try {
    const res = await axios.post(
      global.config.env.API_SERVER + "clients",
      dataSend,
      {
        headers: {
          "Api-Token": localStorage.getItem("Api_Token"),
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log(res)
    dispatch({
      type: ADD_CLIENT,
      payload: res.data.client,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_CREATE_CLIENT,
    });
  }
};
export const updateClientData = (client, id) => async (dispatch, getState) => {
    dispatch({
      type: LOADING_CLIENTS,
    });
    // console.log(client, id);
    try {
      const res = await axios.post(
        global.config.env.API_SERVER + "clients/" + id,
        client,
        {
          headers: {
            "Api-Token": localStorage.getItem("Api_Token"),
          },
        }
      );
      console.log(res);
      dispatch({
        type: UPDATE_CLIENT,
        payload: res,
      });
    } catch (error) {
      dispatch({
        type: ADD_BLOG_FAILED,
        payload: { data: "" },
      });
    }
  };
export const getClientsData = () => async (dispatch, getState) => {
  if(getState().users.userStats?.data.code !== 200){return null}
  if(getState().clients.clients.length === 0){
    // if(getState().clients.loading){return null}
    try {
      const res = await axios.get(global.config.env.API_SERVER + "clients", {
        headers: {
          "Api-Token": localStorage.getItem("Api_Token"),
        },
      });
      dispatch({
        type: GET_CLIENTS,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  }
};
export const getClientData = (id) => async (dispatch, getState) => {
  dispatch({type: LOADING_CLIENTS});
  try {
    const res = await axios.get(global.config.env.API_SERVER + "clients/" + id, {
      headers: {
        "Api-Token": localStorage.getItem("Api_Token"),
      },
    });
    dispatch({
      type: GET_CLIENT,
      payload: res.data[0],
    });
  } catch (error) {
    console.log(error);
  }
};
export const deleteClient = (id) => async (dispatch, getState) => {
  dispatch({
    type: LOADING_CLIENTS,
  });
  try {
    const res = await axios.delete(global.config.env.API_SERVER + "clients/" + id, {
      headers: {
        "Api-Token": localStorage.getItem("Api_Token"),
      },
    });
    dispatch({
      type: DELETE_CLIENT,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};
export const clearClientStatus = () => async (dispatch, getState) => {
  dispatch({
    type: CLEAR_STATUS_ACTION,
  });
};


