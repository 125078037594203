import Modal from "./modal-shift";
import { useDispatch, useSelector } from "react-redux";
import { deleteShift } from "../redux/reducers/shiftsReducer";
import { Link } from "react-router-dom";
import { getEmployeesData } from "../redux/reducers/employeesReducer";
import { useEffect } from "react";

const shift = (props) => {
  const dispatch = useDispatch();
  const { employees, loading } = useSelector((store) => store.employees);

  const getPendings = (shifts) => {
    return shifts.filter((shift) => shift.shift_search).length;
  };

  const getHours = (time) => {
    let hours = time ? time.split(" ")[1] : "";
    return hours;
  };

  const getTotalPriceDay = (shifts) => {
    let total = 0;
    shifts.forEach((shift) => {
      total += parseInt(shift.shift_price);
    });
    return total;
  };

  const getSpanishDay = (date) => {
    switch (date) {
      case "Mon":
        return "LUN";
      case "Tue":
        return "MAR";
      case "Wed":
        return "MIE";
      case "Thu":
        return "JUE";
      case "Fri":
        return "VIE";
      case "Sat":
        return "SÁB";
      case "Sun":
        return "DOM";
      default:
        return "ESP";
    }
  };

  useEffect(() => {
    if (employees.length === 0) {
      dispatch(getEmployeesData());
    }
  }, []);

  return true ? (
    <>
      <div className="shift mb-2">
        <div className="container-fluid">
          <div
            className="header-collapse align-items-center justify-content-between px-0 px-lg-2 row"
            data-toggle="collapse"
            href={
              "#collapse-" +
              getSpanishDay(props.date.toString().split(" ")[0]).toLowerCase()
            }
            role="button"
            aria-expanded="false"
            aria-controls={
              "#collapse-" +
              getSpanishDay(props.date.toString().split(" ")[0]).toLowerCase()
            }
          >
            <div className="col-lg-1 col-3 px-2 px-lg-3 d-flex align-items-center">
              <div className="border-round d-flex align-items-center justify-content-center text-center">
                {getSpanishDay(props.date.toString().split(" ")[0])}
              </div>
            </div>
            <div className="col-lg-4 px-2 px-lg-3 col-4 d-flex flex-wrap align-items-center">
              {props.shifts.map((shift, index) => (
                <div
                  style={{
                    border: `3px solid ${
                      employees.find(
                        (employee) =>
                          employee.id.toString() ===
                          shift.employee_id.toString()
                      ).color
                    }`,
                  }}
                  className={
                    "dog" +
                    // (shift.shift_search ? " pending" : " success") +
                    (index > 4 ? " d-none d-lg-block " : "")
                  }
                >
                  <img
                    src={
                      shift.pet_image
                        ? shift.pet_image
                        : "https://png.pngtree.com/png-vector/20191027/ourlarge/pngtree-cute-pug-avatar-with-a-yellow-background-png-image_1873432.jpg"
                    }
                    alt={shift.pet_name}
                  />
                </div>
              ))}
            </div>
            <div className="col-lg-7 px-2 px-lg-3 col-5 d-flex align-items-center justify-content-end justify-content-lg-between">
              <span
                className={
                  "d-none align-items-center font-semibold " +
                  (props.date === 1 ? "d-lg-none" : "d-lg-flex")
                }
              >
                Hay que buscar {getPendings(props.shifts)} perros por su
                domicilio. <div className="ml-3 pending h-4 w-4"></div>
              </span>
              <span
                className={
                  "d-none align-items-center font-semibold " +
                  (props.date !== 1 ? "d-lg-none" : "d-lg-flex")
                }
              >
                Tienes {props.shifts.length} perro
                {props.shifts.length > 1 && "s"} en espera.{" "}
                <div className="ml-3 pending h-4 w-4"></div>
              </span>
              <div className="both">
                <span className="text-green-400 text-lg font-sans font-semibold mr-lg-3 mr-3">
                  +${getTotalPriceDay(props.shifts)}
                </span>
                <button className="bg-blue-simply rounded-full w-9 h-9">
                  <i className="fas fa-chevron-down text-white"></i>{" "}
                </button>
              </div>
            </div>
          </div>
          <div
            className="shift-collapse collapse bg-white px-lg-3 mx-0 rounded-3xl w-full mt-1 row"
            id={
              "collapse-" +
              getSpanishDay(props.date.toString().split(" ")[0]).toLowerCase()
            }
          >
            {props.shifts.map((element, index) => (
              <div className="col-12 shift_detail py-1.5 border-b-1 border-gray">
                <div className="row">
                  <div className="col-xl-7 col-lg-7 flex align-middle items-center justify-between pr-0">
                    <div className="row no-gutters d-flex w-100 align-items-center justify-between">
                      <div className="col-lg-1 col-2">
                        <div
                          style={{
                            border: `3px solid ${
                              employees.find(
                                (employee) =>
                                  employee.id.toString() ===
                                  element.employee_id.toString()
                              ).color
                            }`,
                          }}
                          className={
                            "dog w-10 mx-auto mx-lg-0 h-10 rounded-full overflow-hidden"
                            //  +
                            // (element.shift_search
                            //   ? " pending-border"
                            //   : " success-border")
                          }
                        >
                          <img
                            className="w-100 h-100 object-cover object-center"
                            src={
                              element.pet_image
                                ? element.pet_image
                                : "https://png.pngtree.com/png-vector/20191027/ourlarge/pngtree-cute-pug-avatar-with-a-yellow-background-png-image_1873432.jpg"
                            }
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-1 col-xl-1 px-1 flex">
                        {/* <span
                          className="color-employee"
                          style={{
                            background: `${
                              employees.find(
                                (emp) =>
                                  emp.id.toString() ===
                                  element.employee_id.toString()
                              )?.color || "red"
                            }`,
                          }}
                        ></span> */}
                        <h5 className="name text-center text-sm">
                          <a
                            href=""
                            className="font-semibold "
                            data-toggle="modal"
                            data-target={"#shift-" + element.shift_id}
                            onClick={(e) => e.preventDefault}
                          >
                            {" "}
                            {element.pet_name}
                          </a>
                        </h5>
                      </div>
                      <div className="col-lg-3 d-none px-1">
                        <h5 className="raza text-center text-sm">
                          {element.pet_race}
                        </h5>
                      </div>
                      <div className="col-lg-2 d-none px-1 justify-content-center d-lg-block my-3 my-lg-0">
                        <h5
                          className={
                            "address d-inline-block mx-0 d-lg-block text-center px-2 py-1.5 text-sm rounded-2xl font-bold text-white " +
                            (element.shift_search ? "" : " success")
                          }
                        >
                          {element.client_address}
                        </h5>
                      </div>
                      <div className="col-lg-1 col-3 col-xl-2 px-2">
                        <h5 className="horario text-center text-sm">
                          {getHours(element.shift_date)}
                        </h5>
                      </div>
                      <div className="col-lg-2 d-lg-block d-none">
                        <h5 className="tipo text-center text-sm">
                          {element.shift_cut_type !== "" &&
                          element.shift_cut_type.toLowerCase() === "sin corte"
                            ? "Sin Corte"
                            : "Corte(" + element.shift_cut_type + ")"}{" "}
                          {element.shift_cut_type && element.shift_washed
                            ? "/"
                            : ""}{" "}
                          {element.shift_washed ? "Baño" : ""}
                        </h5>
                      </div>
                      <div className="col-5 d-lg-none">
                        <div className="row">
                          <div className="col-lg-6">
                            <h5 className="description text-sm text-black ">
                              {
                                <a
                                  href=""
                                  className="font-bold"
                                  data-toggle="modal"
                                  data-target={"#shift-" + element.shift_id}
                                  onClick={(e) => e.preventDefault}
                                >
                                  Ver descripción
                                </a>
                              }
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-5 d-none d-lg-flex col-lg-5 flex align-middle items-center justify-between pl-lg-0">
                    <div className="row w-100 text-center text-lg-start justify-between align-items-center">
                      <div className="col-lg-6">
                        <h5 className="description text-sm">
                          {
                            <a
                              href=""
                              data-toggle="modal"
                              data-target={"#shift-" + element.shift_id}
                              onClick={(e) => e.preventDefault}
                            >
                              Ver descripción del turno
                            </a>
                          }
                        </h5>
                      </div>
                      <div className="col-lg-6 align-items-center justify-end d-lg-flex">
                        <h4 className="text-green-400 d-inline-block text-md font-sans font-semibold text-end mr-5">
                          + $ {element.shift_price}
                        </h4>
                        <div className="actions d-flex align-items-center justify-end">
                          <Link to={"/shifts/update/" + element.shift_id}>
                            <button className="edit pending p-2 text-white mr-2">
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M11.7001 0.108521C11.4127 0.109216 11.1284 0.166799 10.8634 0.277948C10.5984 0.389096 10.3581 0.55161 10.1562 0.756114L1.75558 9.15624L1.72265 9.32252L1.14146 12.2444L0.975708 13.0243L1.75611 12.8585L4.67799 12.2773L4.84374 12.2439L13.2444 3.84374C13.4494 3.64225 13.6123 3.40194 13.7235 3.13683C13.8346 2.87173 13.8919 2.58713 13.8919 2.29966C13.8919 2.01219 13.8346 1.7276 13.7235 1.46249C13.6123 1.19738 13.4494 0.957074 13.2444 0.755583C13.0424 0.551112 12.802 0.388659 12.5369 0.277601C12.2719 0.166542 11.9874 0.109077 11.7001 0.108521ZM11.7001 1.12108C11.9678 1.12108 12.2377 1.24327 12.4969 1.50305C13.0138 2.01943 13.0138 2.5799 12.4969 3.0968L12.1155 3.46177L10.5377 1.88449L10.9032 1.50305C11.163 1.24327 11.4323 1.12108 11.7001 1.12108ZM9.79127 2.63196L11.368 4.20924L4.94361 10.6331C4.59679 9.95453 4.04462 9.40273 3.3658 9.05636L9.79127 2.63196ZM2.6858 9.92018C3.00022 10.0467 3.28582 10.2354 3.52546 10.475C3.76511 10.7147 3.95384 11.0003 4.08033 11.3147L2.33677 11.6632L2.6858 9.92018Z"
                                  fill="white"
                                />
                              </svg>
                            </button>
                          </Link>
                          <button
                            onClick={() =>
                              dispatch(deleteShift(element.shift_id))
                            }
                            className="delete p-2 bg-red-500 rounded-full"
                          >
                            <svg
                              width="12"
                              height="14"
                              viewBox="0 0 12 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.69231 5.0769C3.81472 5.0769 3.93211 5.12553 4.01867 5.21209C4.10522 5.29864 4.15385 5.41604 4.15385 5.53844V11.0769C4.15385 11.1993 4.10522 11.3167 4.01867 11.4033C3.93211 11.4898 3.81472 11.5384 3.69231 11.5384C3.5699 11.5384 3.45251 11.4898 3.36596 11.4033C3.2794 11.3167 3.23077 11.1993 3.23077 11.0769V5.53844C3.23077 5.41604 3.2794 5.29864 3.36596 5.21209C3.45251 5.12553 3.5699 5.0769 3.69231 5.0769ZM6 5.0769C6.12241 5.0769 6.23981 5.12553 6.32636 5.21209C6.41292 5.29864 6.46154 5.41604 6.46154 5.53844V11.0769C6.46154 11.1993 6.41292 11.3167 6.32636 11.4033C6.23981 11.4898 6.12241 11.5384 6 11.5384C5.8776 11.5384 5.7602 11.4898 5.67365 11.4033C5.58709 11.3167 5.53847 11.1993 5.53847 11.0769V5.53844C5.53847 5.41604 5.58709 5.29864 5.67365 5.21209C5.7602 5.12553 5.8776 5.0769 6 5.0769ZM8.76924 5.53844C8.76924 5.41604 8.72061 5.29864 8.63405 5.21209C8.5475 5.12553 8.4301 5.0769 8.3077 5.0769C8.18529 5.0769 8.06789 5.12553 7.98134 5.21209C7.89478 5.29864 7.84616 5.41604 7.84616 5.53844V11.0769C7.84616 11.1993 7.89478 11.3167 7.98134 11.4033C8.06789 11.4898 8.18529 11.5384 8.3077 11.5384C8.4301 11.5384 8.5475 11.4898 8.63405 11.4033C8.72061 11.3167 8.76924 11.1993 8.76924 11.0769V5.53844Z"
                                fill="white"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M12 2.76923C12 3.01405 11.9027 3.24883 11.7296 3.42194C11.5565 3.59506 11.3217 3.69231 11.0769 3.69231H10.6154V12C10.6154 12.4896 10.4209 12.9592 10.0747 13.3054C9.72844 13.6516 9.25886 13.8462 8.76923 13.8462H3.23077C2.74114 13.8462 2.27156 13.6516 1.92534 13.3054C1.57912 12.9592 1.38462 12.4896 1.38462 12V3.69231H0.923077C0.678262 3.69231 0.443473 3.59506 0.270363 3.42194C0.0972524 3.24883 0 3.01405 0 2.76923V1.84615C0 1.60134 0.0972524 1.36655 0.270363 1.19344C0.443473 1.02033 0.678262 0.923077 0.923077 0.923077H4.15385C4.15385 0.678262 4.2511 0.443473 4.42421 0.270363C4.59732 0.0972525 4.83211 0 5.07692 0L6.92308 0C7.16789 0 7.40268 0.0972525 7.57579 0.270363C7.7489 0.443473 7.84615 0.678262 7.84615 0.923077H11.0769C11.3217 0.923077 11.5565 1.02033 11.7296 1.19344C11.9027 1.36655 12 1.60134 12 1.84615V2.76923ZM2.41662 3.69231L2.30769 3.74677V12C2.30769 12.2448 2.40494 12.4796 2.57806 12.6527C2.75117 12.8258 2.98595 12.9231 3.23077 12.9231H8.76923C9.01405 12.9231 9.24883 12.8258 9.42194 12.6527C9.59505 12.4796 9.69231 12.2448 9.69231 12V3.74677L9.58338 3.69231H2.41662ZM0.923077 2.76923V1.84615H11.0769V2.76923H0.923077Z"
                                fill="white"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Modal data={{ element }} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  ) : (
    ""
  );
};

export default shift;
