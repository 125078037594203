import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {setMenu} from "../redux/reducers/userReducer";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { useHistory } from "react-router-dom";

const Sidebar = () => {
    const curLocation = window.location.pathname.split("/")[1]
    const [location,setLocation] = useState(curLocation)
    const dispatch = useDispatch();

    const menu = useSelector((store) => store.users.menuOpen);
    const history = useHistory()

    const {data} = useSelector((store) => store.users.userStats);
    const pets = useSelector((store) => store.pets.pets);

    const [hidden,setHideen] = useState(false)

    const formatResult = (item) => {
      return item;
    }
  
    const handleOnSelect = (item) => {
      history.push(`/mascotas/${item.id}`)
      dispatch(setMenu(false))
  }
  
    const getEditedPets = (pets) => {
      const petEdited = []
      pets.map((pet) => {
          petEdited.push(
              {
                  client:pet.client,
                  description: pet.description,
                  id: pet.id,
                  image: "https://png.pngtree.com/png-vector/20191027/ourlarge/pngtree-cute-dog-avatar-with-a-yellow-background-png-image_1873421.jpg",
                  name: pet.name + " (" + pet?.client?.name + " - " + pet?.client?.phone+")",
                  race: pet.race,
                  size: pet.size,
              }
          )
      })
      return petEdited
    }

    useEffect(() => {
        setLocation(curLocation)
    },[])
    return(
        <>
            <aside class={"main-sidebar mt-0 pt-0 col-12 col-md-12 col-lg-3 px-0 " + (menu && "open-mobile")}>
                <div class="main-navbar my-2 pt-0 py-2 ml-0 px-0">
                    <nav class="navbar align-items-center navbar-light bg-white flex-md-nowrap border-bottom p-0">
                        <a class="navbar-brand w-100 mr-0" href="#" style={{"line-height": 25}}>
                        <div class="d-flex align-items-center my-auto ml-4">
                            <img id="main-logo" class="d-inline-block align-top mr-1" style={{"max-width": 25}} src="/images/favicon.png" alt="Shards Dashboard" />
                            <span class="title-brand d-md-inline ml-1">SIMPLY</span>
                        </div>
                        </a>
                        <a onClick={() => dispatch(setMenu(false))} class="toggle-sidebar d-sm-inline d-lg-none">
                        <i class="material-icons">&#xE5C4;</i>
                        </a>
                    </nav>
                    </div>
                    <form action="#" class="px-3 main-sidebar__search w-100 border-right  d-lg-none">
                        <ReactSearchAutocomplete    
                            items={(getEditedPets(pets))}
                            onSelect={handleOnSelect}
                            formatResult={formatResult}        
                        />
                    </form>
                    <div class="nav-wrapper">
                    <ul class="nav flex-column">
                        <li class="nav-item">
                        <Link class={'nav-link d-flex align-items-center ' + (location == '' ? 'active' : '')} onClick={() => setLocation("") +  dispatch(setMenu(false))} to="/">
                            <i className="icon-home"></i>
                            <span className="ml-1">Inicio</span>
                        </Link>
                        </li>
                        <li class="nav-item">
                        <Link class={'nav-link ' + (location == 'shifts' ? 'active' : '')} onClick={() => setLocation('shifts') + dispatch(setMenu(false))} to="/shifts">
                            <i class="icon-shifts"></i>
                            <span className="ml-1">Turnos</span>
                        </Link>
                        </li>
                        <li class="nav-item">
                        <Link class={'nav-link ' + (location == 'clientes' ? 'active' : '')} onClick={() => setLocation('clientes') + dispatch(setMenu(false))} to="/clientes">
                            <i class="icon-clients"></i>
                            <span className="ml-1">Clientes</span>
                        </Link>
                        </li>
                        <li class="nav-item">
                        <Link class={'nav-link ' + (location == 'mascotas' ? 'active' : '')} onClick={() => setLocation('mascotas') + dispatch(setMenu(false))} to="/mascotas">
                            <i class="icon-pets"></i>
                            <span className="ml-1">Mascotas</span>
                        </Link>
                        <Link class={'nav-link ' + (location == 'metricas' ? 'active' : '')} onClick={() => setLocation('metricas') + dispatch(setMenu(false))} to="/metricas">
                            {/* <i class="material-icons">table_chart</i> */}
                            <i className="icon-metrics"></i>
                            <span className="ml-1">Metricas</span>
                        </Link>
                        </li>
                        {/* <li class="nav-item">
                        <Link class={'nav-link ' + (location == 'tablas' ? 'active' : '')} onClick={() => setLocation('tablas')} to="/tablas">
                            <i class="material-icons">table_chart</i>
                            <span>Tablas</span>
                        </Link>
                        </li> */}
                        <li class="nav-item">
                        <Link class={'nav-link ' + (location == 'ajustes' ? 'active' : '')} onClick={() => setLocation('ajustes') + dispatch(setMenu(false))} to="/ajustes">
                            <i class="icon-gears"></i>
                            <span>Configuraciones</span>
                        </Link>
                        </li>
                        {/* <li class="nav-item">
                        <a class="nav-link " href="errors.html">
                            <i class="material-icons">error</i>
                            <span>Errors</span>
                        </a>
                        </li> */}
                    </ul>
                </div>
            </aside>
            <div className={"full-screen-payment-required position-fixed w-100 justify-content-center align-items-center " + ((data?.code === 402 || data?.alert_expiration) && !hidden ? 'd-flex' : 'd-none')}>
                <div className={"center-message d-flex justify-content-center align-items-center flex-column "}>
                    <img id="" class="d-inline-block align-top mr-1" style={{"max-width": "3vw"}} src="/images/favicon.png" alt="Shards Dashboard" />
                    <h5 className="mt-4 font-medium">¡Bienvenido devuelta {data?.user?.name}!</h5>
                    <h2 className="mt-0">{data?.code === 402 ? "Empeza a disfrutar de Simply" : "¡Se acerca la hora!"}</h2>
                    <p className="mt-2 w-100 px-5 max-w-3xl text-center">{data?.code === 402 ? "Gestiona los turnos, las mascotas y las finanzas de tu negocio." : "Queremos que te quedes con nosotros, visitá nuestra página web para renovar tu plan y seguir disfrutando de esta plataforma."}</p>
                    <div className="d-flex justify-content-center">
                        <a href="" className="btn px-5 py-2 mx-2 mt-5 btn-simply">Ver planes</a>
                        <button onClick={() => setHideen(true)} className="btn px-5 py-2 mx-2 mt-5 btn-white">Mas tarde</button>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Sidebar