import Main from "../components/settings/main";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearUserStatus, getUserData } from "../redux/reducers/userReducer";

import Header from "../components/header.jsx";
import Sidebar from "../components/sidebar.jsx";
import toast, { Toaster } from "react-hot-toast";

const Settings = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector((store) => store.users.userStats).data;
  const response = useSelector((store) => store.users.response);
  const loading = useSelector((store) => store.users.loading);
  const logged = useSelector((store) => store.users.logged);

  useEffect(() => {
    // dispatch(getUserData());
    if (!localStorage.getItem("Api_Token")) {
      history.push("/login");
    }
    if (!loading) {
      if (!logged) {
        history.push("/login");
      }
    }
  }, [loading]);

  useEffect(() => {
    if(response.status){
      if(response.status === 200){
        toast.success(response.message)
      }
      if(response.status === 400){
        toast.error(response.message)
      }
    }
    // setTimeout(function(){ dispatch(clearUserStatus()) }, 1000);
  } , [response]);

  return !loading ? (
    <div>
      <Toaster />
      <Header />
      <Sidebar />
      <div class="main-content-container container-fluid px-4">
        <div class="row">
          <Main />
        </div>
      </div>
    </div>
  ) : (
    ""
  );
};

export default Settings;
