import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Shift from "../shift";
import { useEffect } from "react";
import { getEmployeesData } from "../../redux/reducers/employeesReducer";

const listBlogs = () => {
  const shifts = useSelector((store) => store.shifts.shifts);
  const employees = useSelector((store) => store.employees.employees);
  const [collapsed, setCollapsed] = useState(false);
  const dispatch = useDispatch();

  function dateToYMD(date) {
    var d = date.getDate();
    var m = date.getMonth() + 1; //Month from 0 to 11
    var y = date.getFullYear();
    return "" + (d <= 9 ? "0" + d : d) + "/" + (m <= 9 ? "0" + m : m) + "/" + y;
  }

  function sumarDias(fecha, dias) {
    fecha.setDate(fecha.getDate() + dias);
    return fecha;
  }

  const getWeekShifts = (shifts) => {
    const today = new Date();
    let weekShifts = [];
    var day = today;
    if (shifts) {
      for (let i = 0; i < 7; i++) {
        const dayShifts = shifts.filter((shift) => {
          if (
            shift.shift_date &&
            (shift.shift_waiting.toString() === "0" ||
              shift.shift_waiting.toString() === "false")
          ) {
            return (
              shift.shift_date.split(" ")[0].replace(/-/g, "/") ===
              dateToYMD(day)
            );
          }
        });
        weekShifts.push(dayShifts);
        day = sumarDias(today, 1);
      }
    }
    return weekShifts;
  };

  const getWaitings = (shifts) => {
    return shifts.filter(
      (element) =>
        element?.shift_waiting?.toString() === "true" ||
        element?.shift_waiting?.toString() === "1"
    );
  };

  useEffect(() => {
    if (employees.length === 0) {
      dispatch(getEmployeesData());
    }
  }, []);

  const [filterEmployee, setFilterEmployee] = useState(
    employees.map((employee) => employee.id)
  );
  const [shiftsFilter, setShiftsFilter] = useState([]);

  useEffect(() => {
    if (filterEmployee.length === 0) {
      setShiftsFilter(shifts);
    } else {
      setShiftsFilter([]);
      const shiftsWithEmployee = [];
      for (const id_employee of filterEmployee) {
        shiftsWithEmployee.push(
          ...shifts.filter((shift) => shift.employee_id === id_employee)
        );
        // setShiftsFilter([...shiftsFilter,...])
      }
      setShiftsFilter(shiftsWithEmployee);
    }
  }, [filterEmployee]);

  const handleFilterEmployee = (employee) => {
    const finder = filterEmployee.find(
      (e) => e.toString() === employee.toString()
    );
    if (finder) {
      setFilterEmployee(
        filterEmployee.filter((e) => e.toString() !== employee.toString())
      );
    } else {
      setFilterEmployee([...filterEmployee, employee.toString()]);
    }
  };

  useEffect(() => {
    setFilterEmployee(employees.map((e) => e.id.toString()));
  }, [employees]);


  return (
    <div>
      <div class="page-header row items-center justify-content-between no-gutters py-4 mb-0">
        <div className="d-block">
          <span className="text-uppercase page-subtitle">CALENDARIO</span>
          <h3 className="page-title">Próximos turnos</h3>
        </div>
        <Link to="/shifts/add" className="btn btn-simply">
          Agregar turno
        </Link>
      </div>
      <div class="row mt-5">
        <div class="col-lg-12 col-sm-12 mb-4 px-lg-2">
          <h4
            style={{ fontSize: "1.2rem", letterSpacing: "0px" }}
            className="ml-2 mb-2"
          >
            En espera
          </h4>
          {getWaitings(shifts).length > 0 ? (
            <Shift date={1} shifts={getWaitings(shifts)} isWaiting={true} />
          ) : (
            <span className="mt-2 d-block">No hay turnos en espera.</span>
          )}
        </div>
        <div className={"col-12 mb-4 "}>
          <hr style={{ width: "100%" }} />
        </div>
        {/* {employees.map((employee) => ( */}
        <>
          <div className="col-12 flex items-center mb-3">
            <h4
              style={{ fontSize: "1.2rem", letterSpacing: "0px" }}
              className="ml-2 mr-10"
            >
              Asignados proxima semana
            </h4>
            {employees.map((employee) => (
              <div
                className={
                  "flex items-center " +
                  (filterEmployee.find(
                    (e) => e.toString() === employee.id.toString()
                  )
                    ? ""
                    : "is-disabled")
                }
                onClick={() => handleFilterEmployee(employee.id)}
              >
                <div
                  className={"w-4 h-4 rounded-full mr-2 "}
                  style={{ background: `${employee.color}` }}
                ></div>
                <label className="text-xs mr-4 mb-0">
                  {employee.name} {employee.lastname}
                </label>
              </div>
            ))}
          </div>
          {getWeekShifts(shiftsFilter).map((dayShifts, index) => (
            <div class="col-lg-12 col-sm-12 px-lg-2">
              <Shift shifts={dayShifts} date={sumarDias(new Date(), index)} />
            </div>
          ))}
        </>
        {/* ))} */}
        <div className="col-12 mb-lg-4 ml-2 mt-5 underline">
          <Link className="" to={"/history"}>
            Ver historial de turnos
          </Link>
        </div>
      </div>
    </div>
  );
};

export default listBlogs;
