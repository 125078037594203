import axios from "axios";
import "../../config";
// constantes
const dataInicial = {
  blogs: [{}],
  loading: true,
  blogCreated: {},
};

// types
const GET_BLOGS = "GET_BLOGS";
const ADD_BLOG = "ADD_BLOG";
const ADD_BLOG_FAILED = "ADD_BLOG_FAILED";
const UPDATE_BLOG = "UPDATE_BLOG";
const LOADING = "LOADING";

// reducer
export default function dataReducer(state = dataInicial, action) {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: true };
    case GET_BLOGS:
      return { ...state, blogs: action.payload, loading:false};
    case ADD_BLOG:
      return {
        ...state,
        blogs: [...state.blogs, action.payload],
        loading: false,
      };
    case UPDATE_BLOG:
      return {
        ...state,
        blogs: [
          ...state.blogs.filter((element) => element.id != action.payload.id),
          action.payload,
        ],
        loading: false,
      };

    default:
      return state;
  }
}

// actions
export const addBlogData = (blogData, image) => async (dispatch, getState) => {
  dispatch({
    type: LOADING,
  });
  var dataSend = new FormData();
  dataSend.append("title", blogData.title);
  dataSend.append("description", blogData.description);
  dataSend.append("author_id", localStorage.getItem("id"));
  dataSend.append("author_img", blogData.author_img);
  dataSend.append("tag", blogData.tag);
  if (image) {
    dataSend.append("image", image);
  }
  try {
    const res = await axios.post(
      global.config.env.API_SERVER + "blogs",
      dataSend,
      {
        headers: {
          "Api-Token": localStorage.getItem("Api_Token"),
          "Content-Type": "multipart/form-data",
        },
      }
    );
    dispatch({
      type: ADD_BLOG,
      payload: res.data.blog,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ADD_BLOG_FAILED,
      payload: { data: "" },
    });
  }
};
export const updateBlogData = (blogData, image) => async (dispatch, getState) => {
    dispatch({
      type: LOADING,
    });
    console.log(blogData, image);
    var dataSend = new FormData();
    dataSend.append("title", blogData.title);
    dataSend.append("description", blogData.description);
    dataSend.append("author_id", localStorage.getItem("id"));
    dataSend.append("author_img", blogData.author_img);
    dataSend.append("tag", blogData.tag);
    if (image) {
      dataSend.append("image", image);
    }
    try {
      const res = await axios.post(
        global.config.env.API_SERVER + "blogs/" + blogData.id,
        dataSend,
        {
          headers: {
            "Api-Token": localStorage.getItem("Api_Token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(res);
      dispatch({
        type: UPDATE_BLOG,
        payload: res.data.blog,
      });
    } catch (error) {
      dispatch({
        type: ADD_BLOG_FAILED,
        payload: { data: "" },
      });
    }
  };
export const getBlogData = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(global.config.env.API_SERVER + "blogs", {
      headers: {
        "Api-Token": localStorage.getItem("Api_Token"),
      },
    });
    dispatch({
      type: GET_BLOGS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};
export const deleteBlog = (id) => async (dispatch, getState) => {
  dispatch({
    type: LOADING,
  });
  try {
    const res = await axios.delete(global.config.env.API_SERVER + "blogs/" + id, {
      headers: {
        "Api-Token": localStorage.getItem("Api_Token"),
      },
    });
    dispatch({
      type: DELETE_BLOG,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

