import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Main from '../components/home/main'
import Table from '../components/tables/detailClient'
import {useParams} from "react-router-dom";


import { getClientData } from "../redux/reducers/clientsReducer";

const Home = () =>{
    const dispatch = useDispatch();
    const {id} = useParams();

    useEffect(() => {
        dispatch(getClientData(id))
    },[]);

    return(
        <div class="main-content-container container-fluid px-4">
            <div class="row">
                <Table />
            </div>
        </div>
    )
}

export default Home