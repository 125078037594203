import { Link } from "react-router-dom";
import { es } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
);

export const optionsMobile = {
  responsive: true,
  aspectRatio: 1,
  plugins: {
    legend: {
      position: "top",
    },
    // title: {
    //   display: true,
    //   text: 'Ingresos',
    // },
  },
};
export const optionsDesktop = {
  responsive: true,
  aspectRatio: screen.height / 500,
  plugins: {
    legend: {
      position: "top",
    },
    // title: {
    //   display: true,
    //   text: 'Ingresos',
    // },
  },
};

import { useDispatch, useSelector } from "react-redux";
import {
  getShiftsData,
  getGraphShiftsAction,
} from "../../redux/reducers/shiftsReducer";
import OwlCarousel from "react-owl-carousel2";
import "../../../node_modules/react-owl-carousel2/src/owl.carousel.css"; //Allows for server-side rendering

import { DateRangePicker } from "react-date-range";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { getEmployeesData } from "../../redux/reducers/employeesReducer";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

const Main = () => {
  const dispatch = useDispatch();

  const pets = useSelector((store) => store.pets.pets);
  const clients = useSelector((store) => store.clients.clients);
  const shifts = useSelector((store) => store.shifts.shifts);
  const graph_resume = useSelector((store) => store.shifts.graph_resume);
  const employees = useSelector((store) => store.employees.employees);

  const [startFilter, setStartFilter] = useState(new Date());
  const [endFilter, setEndFilter] = useState(new Date());

  const [filteredRange, setFilteredRange] = useState({
    from: new Date(),
    to: new Date(),
  });

  const [filterEmployee,setFilterEmployee] = useState('')

  const [openFilter, setOpenFilter] = useState(false);

  const [data, setData] = useState();

  console.log(filterEmployee)

  const selectionRange = {
    startDate: startFilter,
    endDate: endFilter,
    key: "selection",
  };

  const getLast30Days = () => {
    const date = new Date();
    const last30Days = [];
    for (let i = 0; i < 30; i++) {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      last30Days.push(
        `${day > 9 ? day : `0${day}`}-${
          month > 9 ? month : `0${month}`
        }-${date.getFullYear()}`
      );
      date.setDate(date.getDate() - 1);
    }
    return last30Days.reverse();
  };

  const optionsSlider = {
    items: 1,
    nav: true,
    rewind: true,
    autoplay: true,
  };

  useEffect(() => {
    dispatch(getGraphShiftsAction(getLast30Days()[0], getLast30Days()[29]));
    if (!employees) {
      dispatch(getEmployeesData());
    }
  }, []);

  useEffect(() => {
    if (graph_resume?.length > 0) {
      setData({
        labels: graph_resume.map((element) => element.date),
        datasets: [
          {
            label: "Ingresos",
            data: graph_resume.map((element) =>
              element.shifts[0].total_day === null
                ? 0
                : element.shifts[0].total_day
            ),
            borderColor: "#26A9E0",
            backgroundColor: "#26A9E0",
          },
        ],
      });
    }
  }, [graph_resume]);

  const handleChange = (ranges) => {
    setStartFilter(ranges.selection.startDate);
    setEndFilter(ranges.selection.endDate);
  };

  const searchInRange = () => {
    const fromDay = startFilter.getDate();
    const fromMonth = startFilter.getMonth() + 1;
    const fromDate = `${fromDay > 9 ? fromDay : `0${fromDay}`}-${
      fromMonth > 9 ? fromMonth : `0${fromMonth}`
    }-${startFilter.getFullYear()}`;

    const untilDay = endFilter.getDate();
    const untilMonth = endFilter.getMonth() + 1;
    const untilDate = `${untilDay > 9 ? untilDay : `0${untilDay}`}-${
      untilMonth > 9 ? untilMonth : `0${untilMonth}`
    }-${endFilter.getFullYear()}`;
    dispatch(getGraphShiftsAction(fromDate, untilDate,filterEmployee));
    setFilteredRange({ from: startFilter, to: endFilter });
  };

  //function to converte date to string dd of month of year
  const dateToString2 = (date) => {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    return day + " de " + monthToString(month) + " de " + year;
  };

  //function to convert number month to string month  (1 = enero)
  const monthToString = (month) => {
    switch (month) {
      case 1:
        return "Enero";
      case 2:
        return "Febrero";
      case 3:
        return "Marzo";
      case 4:
        return "Abril";
      case 5:
        return "Mayo";
      case 6:
        return "Junio";
      case 7:
        return "Julio";
      case 8:
        return "Agosto";
      case 9:
        return "Septiembre";
      case 10:
        return "Octubre";
      case 11:
        return "Noviembre";
      case 12:
        return "Diciembre";
    }
  };

  const getTotal = (shifts) => {
    let total_price = 0;
    let total_shifts = 0;
    shifts.map(
      (element) => (
        // console.log(element.shifts[0]?.total_day === null || !element.shifts[0]?.total_day || element.shifts[0]?.total_day === undefined ? 0 : parseInt(element.shifts[0].total_day))
        (total_price +=
          element.shifts[0]?.total_day === null ||
          !element.shifts[0]?.total_day ||
          element.shifts[0]?.total_day === undefined
            ? 0
            : parseInt(element.shifts[0].total_day)),
        (total_shifts +=
          element.shifts[0]?.count_day === null ||
          !element.shifts[0]?.count_day ||
          element.shifts[0]?.count_day === undefined
            ? 0
            : parseInt(element.shifts[0].count_day))
      )
    );
    return { total_price, total_shifts };
  };

  const getEmployeesFormated = (employees) => {
    const employees_edited = [];
    for (const iterator of employees) {
      employees_edited.push({
        id: iterator.id,
        name: iterator.name + " " + iterator.lastname,
        color: iterator.color
      });
    }
    return employees_edited;
  };

  const formatResult = (item) => {
    return <span className="d-flex align-items-center"> <div className="mr-1 color w-3 h-3 rounded-sm" style={{backgroundColor:employees.find(e => item.toLowerCase().includes(e.name.toLowerCase()))?.color}}></div> {item} </span>
  };

  const handleOnSelect = (item) => {
    setFilterEmployee(item.id)
  }

  const handleClear = () => {
    setFilterEmployee('')
  }

  return graph_resume?.length > 0 && data ? (
    <div className="metrics_page">
      <div class="page-header row no-gutters py-4 mt-5 mt-lg-0">
        <div class="col-12 text-center text-sm-left mb-0">
          <span class="text-uppercase page-subtitle d-block mb-1 mb-lg-0">
            METRICAS
          </span>
          <h3 class="page-title">Panel global de administración</h3>
        </div>
      </div>
      <div className="mt-2 row flex items-stretch self-stretch">
        <div className="col-12 d-lg-none d-block">
          <OwlCarousel
            className="owl-carousel align-middle justify-center h-100"
            options={optionsSlider}
            item={1}
            loop
            margin={0}
            nav={false}
          >
            <div
              style={{ height: "50vh", width: "90vw" }}
              class="p-3 d-flex align-items-center justify-center stats-small--1 card mb-5 mb-lg-0"
            >
              {graph_resume.length > 0 && (
                <Line options={optionsMobile} data={data} />
              )}
            </div>
          </OwlCarousel>
        </div>
        <div className="col-lg-8 d-none d-lg-flex align-items-start justify-content-between">
          <div class="p-lg-5 w-100 stats-small--1 card mb-5 mb-lg-0">
            {graph_resume.length > 0 && (
              <div className="">
                <Bar options={optionsDesktop} data={data} />
              </div>
            )}
          </div>
        </div>
        <div className="col-lg-4 pr-lg-5 px-2 px-lg-0 flex items-stretch self-stretch justify-self-stretch w-100 w-full mb-4 mb-lg-0">
          <div className="card flex items-center py-lg-0 py-5 self-stretch justify-center w-100 w-full">
            <h4 className="text-lg text-center font-normal">
              {filteredRange.from.getTime() === filteredRange.to.getTime() ? (
                <>
                  En los últimos <b> {graph_resume.length} días </b>
                  <br />
                </>
              ) : (
                <>
                  Desde el día <b>{dateToString2(filteredRange.from)}</b> <br />
                  Hasta el dia <b>{dateToString2(filteredRange.to)}</b> <br />
                </>
              )}
               {filterEmployee ? employees.find(e => e.id === filterEmployee)?.name + " " +employees.find(e => e.id === filterEmployee)?.lastname + ' tuvo' : 'Hubo'} un total de <b> {getTotal(graph_resume).total_shifts} </b>{" "}
              turnos registrados <br />
              Con unos ingresos totales de{" "}
              <span className="text-green-500 font-bold">
                $
                {Intl.NumberFormat("de-DE").format(
                  getTotal(graph_resume).total_price
                )}
              </span>
              <br />
              <br />
              Tenés un total de <b> {pets.length} </b> mascotas registradas 🐶
            </h4>
          </div>
        </div>
        <div className={"sidebar-filter " + (!openFilter && "close")}>
          <div
            onClick={() => setOpenFilter(!openFilter)}
            className="open-sidebar-filter"
          >
            {">"}
          </div>
          <h2>Filtrado de fechas</h2>
          <p className="mt-3">
            Seleccione un rango de fechas para ver su informe
          </p>
          <DateRangePicker
            ranges={[selectionRange]}
            onChange={handleChange}
            locale={es}
            showDateDisplay={false}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            direction="horizontal"
          />
          <p className="mb-1 mt-5">
            Seleccione un empleado para ver sus métricas
          </p>
          <div className="">
            <ReactSearchAutocomplete
              items={getEmployeesFormated(employees)}
              onSelect={handleOnSelect}
              formatResult={formatResult}
              onClear={handleClear}
            />
            {/* {employees.map(e => (
              <div className="border-2 rounded-full mr-2 px-3 py-1" style={{borderColor:e.color}}>{e.name} {e.lastname}</div>
            ))} */}
          </div>
          <button
            onClick={() => searchInRange() + setOpenFilter(false)}
            className="d-inline-block btn-simply mt-lg-5"
          >
            Aplicar
          </button>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
};

export default Main;
