import {createStore, combineReducers, compose, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import userReducer from './reducers/userReducer'
import blogReducer from './reducers/blogReducer'
import petsReducer from './reducers/petsReducer'
import clientsReducer from './reducers/clientsReducer'
import shiftsReducer from './reducers/shiftsReducer'
import employeesReducer from './reducers/employeesReducer'



const rootReducer = combineReducers({
    users: userReducer,
    blogs: blogReducer,
    pets: petsReducer,
    clients: clientsReducer,
    employees: employeesReducer,
    shifts: shiftsReducer
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore() {
    const store = createStore( rootReducer, composeEnhancers( applyMiddleware(thunk) ) )
    return store
}