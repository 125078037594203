import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Header from "../components/header.jsx";
import Sidebar from "../components/sidebar.jsx";

const Home = () => {
    const dispatch = useDispatch();
    const location = useLocation()
    const {search} = useLocation()
    const payment_id = new URLSearchParams(search).get("payment_id");
    const status = new URLSearchParams(search).get("status");

    console.log(useLocation())    

    // collection_id=1308671969
    // collection_status=approved
    // payment_id=1308671969
    // status=approved
    // external_reference=null
    // payment_type=credit_card
    // merchant_order_id=5996149848
    // preference_id=1208790816-d70df6c8-f905-4f47-a9c3-8f97ca754778
    // site_id=MLA
    // processing_mode=aggregator
    // merchant_account_id=null

    return (
        <div>
            <Header />
            <Sidebar />
            <div class="main-content-container container-fluid px-4">
                <div class="page-header row items-center justify-content-between no-gutters py-4 mb-0">
                    <div className="d-block">
                        <span className="text-uppercase page-subtitle">PAGOS</span>
                        <h3 className="page-title">Estado del pago</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h3>Detalles</h3>
                        <p>Código de identificación: {payment_id}</p>
                        <p>Estado: {status}</p>
                        <h5>Será redigido en unos segundos..</h5>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Home;

    