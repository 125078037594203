import axios from "axios";
import "../../config";

// constantes

const dataInicial = {
  userStats: { data: "" },
  users:[],
  loading: false,
  errorMessage:'',
  logged: false,
  menuOpen: false,
  response:{status:0,message:''},
};

// types
const POST_LOGIN = "POST_LOGIN";
const GET_USER_LOGGED = "GET_USER_LOGGED";
const GET_USER_UPDATED = "GET_USER_UPDATED";
const GET_USER_FAILED = "GET_USER_FAILED";
const GET_USERS = "GET_USERS";
const POST_LOGOUT = "POST_LOGOUT";
const LOADING = "LOADING";
const SET_MENU = "SET_MENU";
const CLEAN_STATUS_USER = "CLEAN_STATUS_USER";

// reducer
export default function dataReducer(state = dataInicial, action) {
  switch (action.type) {
    case LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case POST_LOGIN:
      return {
        ...state,
        userStats: action.payload,
        loading: false,
        logged: true,
      };
    case GET_USER_LOGGED:
      return {
        ...state,
        userStats: action.payload,
        loading: false,
        logged: true,
      }
    case GET_USER_UPDATED:
      return {
        ...state,
        userStats: action.payload,
        loading: false,
        logged: true,
        response:{status:200,message:"El perfil se actualizó correctamente."}
      };
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
        loading: false,
      };
    case GET_USER_FAILED:
      return {
        ...state,
        logged:  false,
        loading: false,
        errorMessage: action.payload,
      };
    case SET_MENU:
      return {
        ...state,
        menuOpen: action.payload,
      };
    case CLEAN_STATUS_USER:
      return {
        ...state,
        response: {status:0,response:''},
      };
    case POST_LOGOUT:
      return {
        ...state,
        userStats: { data: "" },
        loading: false,
        logged: false,
      };
    default:
      return state;
  }
}

// actions
export const loginUser = (email, password) => async (dispatch, getState) => {
  dispatch({
    type: LOADING,
  });
  try {
    const res = await axios.post(
      global.config.env.API_SERVER + "login",
      { email: email, password: password },
      {
        headers: {},
      }
    );
    localStorage.setItem("id", res?.data?.id);
    localStorage.setItem("Api_Token", res?.data?.token);
    console.log(res.data)
    switch (res.data.code) {
      case 200: {
        return dispatch({
          type: POST_LOGIN,
          payload: res,
        });
      }
      case 402: {
        return dispatch({
          type: POST_LOGIN,
          payload: res,
        });
      }
      case 403: {
        
        return dispatch({
          type: GET_USER_FAILED,
          payload: 403,
        });
      }
    }
  } catch (error) {
    console.log(error)
    dispatch({
      type: GET_USER_FAILED,
      payload: { data: "Error" },
    });
  }
};
export const logoutUser = () => async (dispatch, getState) => {
  dispatch({
    type: LOADING,
  });
  try {
    const res = await axios.get(global.config.env.API_SERVER + "logout", {
      headers: {
        "Api-Token": localStorage.getItem("Api_Token"),
      },
    });
    localStorage.removeItem("Api_Token");
    dispatch({
      type: POST_LOGOUT,
      payload: res,
    });
  } catch (error) {
    console.log(error);
  }
};
export const getUserData = () => async (dispatch, getState) => {
  const user = getState().users.userStats
  try {
    const res = await axios.get(
      global.config.env.API_SERVER + "user" ,
      {
        headers: {
          "Api-Token": user?.token || localStorage.getItem("Api_Token"),
        },
      }
    );
    console.log(res)
    dispatch({
      type: GET_USER_LOGGED,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: GET_USER_FAILED,
      payload: { data: "Error" },
    });
  }
};
export const getUsersData = () => async (dispatch, getState) => {
  dispatch({
    type: LOADING,
  });
  try {
    const res = await axios.get(
      global.config.env.API_SERVER + "user/",
      {
        headers: {
          "Api-Token": localStorage.getItem("Api_Token"),
        },
      }
    );
    console.log(res)
    dispatch({
      type: GET_USERS,
      payload: res.data.users,
    });
  } catch (error) {
    dispatch({
      type: GET_USER_FAILED,
      payload: { data: "" },
    });
  }
};
export const updateUserData = (userData, image) => async (dispatch, getState) => {
    var dataSend = new FormData();
    dataSend.append("name", userData.name);
    dataSend.append("lastname", userData.lastname);
    // dataSend.append("password", userData.password);
    dataSend.append("description", userData.description);
    dataSend.append("email", userData.email);
    dataSend.append("city", userData.city);
    if (image) {
      dataSend.append("picture_profile", image);
    }
    try {
      const res = await axios.post(
        global.config.env.API_SERVER + "user/" + localStorage.getItem("id"),
        dataSend,
        {
          headers: {
            "Api-Token": localStorage.getItem("Api_Token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const resEdited = {data:res.data}
      dispatch({
        type: GET_USER_UPDATED,
        payload: resEdited,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: GET_USER_FAILED,
        payload: { data: "" },
      });
    }
};
export const setMenu = (value) => async (dispatch, getState) => {
    dispatch({
      type: SET_MENU,
      payload: value,
    });

};
export const clearUserStatus = () => async (dispatch, getState) => {
    dispatch({
      type: CLEAN_STATUS_USER,
      payload: '',
    });

};

// const res = await axios.get('http:localhost:8000/login',{headers: { 'Content-Type': 'application / x-www-form-urlencoded; charset = UTF-8; application / json ' }})
