import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { logoutUser } from "../redux/reducers/userReducer";
import {setMenu} from "../redux/reducers/userReducer";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory()
  const Response = useSelector((store) => store.users.userStats).data;
  const userData = useSelector((store) => store.users.userStats).data?.user;
  const pets = useSelector((store) => store.pets.pets);

  const logoutFunction = () => {
    dispatch(logoutUser())
    localStorage.removeItem("Api_Token")
    history.push("/login")
  };

  const formatResult = (item) => {
    return item;
  }

  const handleOnSelect = (item) => {
    history.push(`/mascotas/${item.id}`)
  }

  useEffect(() => {
    if(Response.alert_expiration){
      $('#modal_expiration_alert').modal('show')
    }
  },[Response])

  const getEditedPets = (pets) => {
    const petEdited = []
    pets.map((pet) => {
        petEdited.push({
          client:pet.client,
          description: pet.description,
          id: pet.id,
          image: "https://png.pngtree.com/png-vector/20191027/ourlarge/pngtree-cute-dog-avatar-with-a-yellow-background-png-image_1873421.jpg",
          name: pet?.name + " (" + pet?.client?.name + " - " + pet?.client?.phone+")",
          race: pet.race,
          size: pet.size,
        })
    })
    return petEdited;
  }

  return (
    <div class="main-navbar sticky-top bg-white">
      <nav class="navbar align-items-stretch justify-content-between w-100 navbar-light flex-md-nowrap p-0">
        <form class="main-navbar__search d-none d-md-flex d-lg-flex">
            <ReactSearchAutocomplete    
              items={getEditedPets(pets)}
              onSelect={handleOnSelect}
              formatResult={formatResult}        
            />
        </form>
        <ul class="navbar-nav border-left flex-row ">
          <li class="d-none nav-item border-right dropdown notifications">
            <a
              class="nav-link nav-link-icon text-center"
              href="#"
              role="button"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div class="nav-link-icon__wrapper">
                <i class="material-icons">&#xE7F4;</i>
                <span class="badge badge-pill badge-danger">2</span>
              </div>
            </a>
            <div
              class="dropdown-menu dropdown-menu-small"
              aria-labelledby="dropdownMenuLink"
            >
              <a class="dropdown-item" href="#">
                <div class="notification__icon-wrapper">
                  <div class="notification__icon">
                    <i class="material-icons">&#xE6E1;</i>
                  </div>
                </div>
                <div class="notification__content">
                  <span class="notification__category">Analytics</span>
                  <p>
                    Your website’s active users count increased by
                    <span class="text-success text-semibold">28%</span> in the
                    last week. Great job!
                  </p>
                </div>
              </a>
              <a class="dropdown-item" href="#">
                <div class="notification__icon-wrapper">
                  <div class="notification__icon">
                    <i class="material-icons">&#xE8D1;</i>
                  </div>
                </div>
                <div class="notification__content">
                  <span class="notification__category">Sales</span>
                  <p>
                    Last week your store’s sales count decreased by
                    <span class="text-danger text-semibold">5.52%</span>. It
                    could have been worse!
                  </p>
                </div>
              </a>
              <a class="dropdown-item notification__all text-center" href="#">
                {" "}
                View all Notifications{" "}
              </a>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle d-flex align-items-center text-nowrap px-3"
              data-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              
            >
              <img
                class="user-avatar rounded-circle mr-2"
                src={userData?.picture_profile}
                alt="User Avatar"
              />
              <span class="d-block d-md-inline-block">{userData?.name}</span>
            </a>
            <div class="dropdown-menu dropdown-menu-small">
              {/* <a class="dropdown-item" href="user-profile-lite.html">
                <i class="material-icons">&#xE7FD;</i> Profile
              </a>
              <a class="dropdown-item" href="components-blog-posts.html">
                <i class="material-icons">vertical_split</i> Blog Posts
              </a>
              <a class="dropdown-item" href="add-new-post.html">
                <i class="material-icons">note_add</i> Add New Post
              </a>
              <div class="dropdown-divider"></div> */}
              <a
                class="dropdown-item text-danger"
                onClick={() => logoutFunction()}
              >
                <i class="material-icons text-danger">&#xE879;</i> Logout{" "}
              </a>
            </div>
          </li>
        </ul>
        <nav class="nav">
          <a
            href="#"
            class="nav-link nav-link-icon toggle-sidebar d-md-inline d-lg-none text-center border-left"
            // data-toggle="collapse"
            // data-target=".j-navbar"
            // aria-expanded="false"
            // aria-controls="header-navbar"
            onClick={() => dispatch(setMenu(true))}
          >
            <i class="material-icons">&#xE5D2;</i>
          </a>
        </nav>
      </nav>
    </div>
  )
};

export default Header;
