import { Link } from "react-router-dom"
import DatePicker from "react-datepicker";
import { useState, useRef, useEffect } from "react";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { useDispatch,useSelector} from "react-redux";

import "react-datepicker/dist/react-datepicker.css";
import { getClientsData } from "../../redux/reducers/clientsReducer";
import { addPetData } from "../../redux/reducers/petsReducer";

const add_pets = () => {

    const [curStatsPet, setCurStats] = useState({
        name: "",
        race: "",
        size:"",
        image:"",
        client_id:"",
        description:"",
        status:1,
    });

    const clients = useSelector((store) => store.clients.clients);

    const [loadingImage, setLoadingImage] = useState(false);
    const picture_profile = useRef();
    const searcherRef = useRef();
    const [imgToUpload, setImgToUpload] = useState();
    const [drag, setDrag] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getClientsData());
    } ,[]);

    const handleOnSelect = (item) => {
        setCurStats({...curStatsPet, client_id: item.id})
    }
    const handleChange = (e) => {
        setCurStats({
            ...curStatsPet,
            [e.target.name]:e.target.value
        })
    }

    const clearList = () => {

    }

    const createPet = (e) => {
        e.preventDefault()
        dispatch(addPetData(curStatsPet, imgToUpload))
        document.getElementById("formCreatePet").reset();
        
    }

    const formatResult = (item) => {
        return item;
    }
    const changeFile = () => {
        const image = picture_profile.current.files[0];
        setImgToUpload(image);
        setCurStats({ ...curStatsPet, image: picture_profile.current.files[0] });
        loadImage(image);
        // dispatch(updateUserData(curStatsPet,image));
    };
    const onDragEnter = (event) => {
        event.preventDefault();
        setDrag(true);
    };
    const onDragLeave = (e) => {
    e.preventDefault();
    setDrag(false);
    };
    const onDrop = (event) => {
    // Drag and drop
    event.preventDefault();
    event.stopPropagation();

    const file = event.dataTransfer.files;
    image.current.files = file;
    };
    const loadImage = (file) => {
        let reader = new FileReader();
        reader.onload = (e) => {
            setCurStats({ ...curStatsPet, image: e.target.result });
        };
        reader.readAsDataURL(file);
    };

    const getClientsFormated = (clients) => {
        const clientsEdited = []
        for (const iterator of clients) {
            clientsEdited.push(
                {
                    id: iterator.id,
                    name: iterator.name + " (" + iterator.phone + ")",
                }
            )
        }
        return clientsEdited;
    }


    return (
        <section className="add_shifts">
            <div class="page-header row items-center justify-content-between no-gutters py-4 mb-3">
                <div className="d-block">
                    <Link to="/mascotas" className="underline color-simply">
                        Volver a mascotas
                    </Link>
                    <span className="text-uppercase page-subtitle d-block mt-4">MASCOTAS</span>
                    <h3 className="page-title">Agregar nueva mascota</h3>
                </div>
                {/* <Link to="/shifts" className="btn btn-simply">
                Agregar mascota
                </Link> */}
            </div>
            <div className="row no-gutters pl-3 overflow-hidden align-items-stretch rounded-3xl">
                <div className="col-lg-8">
                    <form id="formCreatePet" onSubmit={createPet} className="row align-items-center pr-5 h-full">
                        <div className="col-12 mb-3 position-relative">
                            <label className="d-block pl-3 w-100">Nombre de la mascota</label>
                            {/* <input className="w-100" type="text" /> */}
                            <input type="text" name="name" onChange={(e) => handleChange(e)}id="" />
                        </div>
                        <div className="col-lg-4 mb-3 mb-lg-0">
                            <label className="d-block pl-3 w-100">Nombre de su dueño</label>
                            <ReactSearchAutocomplete    
                                items={getClientsFormated(clients)}
                                onSelect={handleOnSelect}
                                formatResult={formatResult}        
                            />
                            <Link to="/clients/add" className="text-gray-500 btn-pet_add font-medium position-absolute flex items-center h-100 justify-content-center text-end text-xs top-0" style={{marginTop:".9rem", right:'4rem'}}>Agregar</Link>

                        </div>
                        <div className="col-lg-4 col-6">
                            <label className="d-block pl-3 w-100">Raza</label>
                            <input type="text" onChange={(e) => handleChange(e)} name="race" />
                            {/* <ReactSearchAutocomplete    
                                items={items}
                                onSelect={handleOnSelect}
                                formatResult={formatResult}        
                            /> */}
                        </div>
                        <div className="col-lg-4 col-6">
                            <label className="d-block pl-3 w-100">Tamaño</label>
                            <select name="size" onChange={(e) => handleChange(e)} className="w-100">
                                <option value="">Seleccionar una opción</option>
                                <option value="Pequeño">Pequeño</option>
                                <option value="Mediano">Mediano</option>
                                <option value="Grande">Grande</option>
                            </select>
                        </div>
                        <div className="col-lg-8 mt-3">
                            <label className="d-block pl-3 w-100">Descripción breve no obligatoria sobre la mascota</label>
                            <textarea name="description" onChange={(e) => handleChange(e)} id="" cols="30" rows="10" placeholder="Información extra no obligatoria sobre el turno.."></textarea>
                        </div>
                        <div className="col-lg-4 mt-3">
                            <div class="mb-3 mx-auto content-img">
                                <img
                                class="rounded-circle"
                                src={(curStatsPet.image ? curStatsPet.image : " https://via.placeholder.com/250")}
                                // src={(curStatsPet.image[0] === 'd' ? process.env.API_URL  : '') + (curStatsPet.image ? curStatsPet.image : " https://via.placeholder.com/250")}
                                alt={curStatsPet.name ? curStatsPet.name : "Mascota"}
                                width="100%"
                                />{" "}
                                <div
                                className={"drag mb-4" + drag}
                                // className="uploader"
                                dragleave={onDragLeave}
                                dragenter={onDragEnter}
                                onDrop={onDrop}
                                >
                                <label for="Addfile" class="labelFile">
                                    <i class="fas fa-camera"></i>
                                </label>
                                <input
                                    className="d-none"
                                    type="file"
                                    name="addFile"
                                    ref={picture_profile}
                                    onChange={changeFile}
                                    accept="image/*;capture=camera"
                                    id="Addfile"
                                />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 mt-3">
                            <button type="submit" className="btn w-100 d-block mt-3 mt-lg-0 d-lg-inline-block">Crear mascota</button>
                        </div>
                    </form>
                </div>
                <div className="col-4 d-none d-lg-block">
                    <img className="w-100 img-responsive h-full object-cover object-center" src="/images/form.jpg" alt="" />
                </div>
            </div>
        </section>
    )
}

export default add_pets