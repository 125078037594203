import axios from "axios";
import "../../config";
// constantes
const dataInicial = {
  pets: [],
  pet: {},
  loading: false,
  response:{}
};

// types
const LOADING_PET = "LOADING_PET";
const GET_PETS = "GET_PETS";
const ADD_PETS = "ADD_PETS";
const GET_PET = "GET_PET";
const UPDATE_PET = "UPDATE_PET";
const DELETE_PET = "DELETE_PET";
const CLEAR_STATUS_ACTION = "CLEAR_STATUS_ACTION";

const GET_ERROR_PETS = "GET_ERROR_PETS";

// reducer
export default function dataReducer(state = dataInicial, action) {
  switch (action.type) {
    case LOADING_PET:
      return { ...state, loading: true };
    case GET_PETS:
      return { ...state,
         pets: action.payload, 
         loading:false,
      };
    case GET_PET:
      return { ...state,
         pet: action.payload, 
         loading:false,
      };
    case ADD_PETS:
      return {
        ...state,
        pets: [...state.pets, action.payload,],
        loading: false,
        response:{
          status:200,
          success:true,
          statusText:["La mascota se ha agregado correctamente"]
        }
      };
    case UPDATE_PET:
      return {
        ...state,
        pets: [
          ...state.pets.filter((element) => element.id.toString() !== action.payload.id.toString()),
          action.payload,
        ],
        response:{
          status:200,
          success:true,
          statusText:["La mascota fue actualizada correctamente"]
        },
        loading: false,
      };
    case DELETE_PET:
      {
      return {
        ...state,
        pets: [
          ...state.pets.filter((element) => element.id != action.payload.id)
        ],
        loading:false
      }
    };
    case CLEAR_STATUS_ACTION:
      {
      return {
        ...state,
        response: {}
      }
    };
    case GET_ERROR_PETS:
      return {
        ...state,
        response:{
          status:400,
          statusText:["Ocurrio un error al crear la mascota."]
        }
      };
    default:
      return state;
  }
}

// actions
export const addPetData = (petData, image) => async (dispatch, getState) => {
  dispatch({
    type: LOADING_PET,
  });
  var dataSend = new FormData();
  dataSend.append("name", petData.name);
  dataSend.append("race", petData.race);
  dataSend.append("client_id", petData.client_id);
  dataSend.append("size", petData.size);
  dataSend.append("description", petData.description);
  if (image) {
    dataSend.append("image", image);
  }
  try {
    const res = await axios.post(global.config.env.API_SERVER + "pets",dataSend,
      {
        headers: {
          "Api-Token": localStorage.getItem("Api_Token"),
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log(res)
    dispatch({
      type: ADD_PETS,
      payload: res.data.pet,
    });
  } catch (error) {
    console.log(error);
    dispatch({type: GET_ERROR_PETS});
  }
};
export const updatePetData = (petData,image = null) => async (dispatch, getState) => {
    dispatch({
      type: LOADING_PET,
    });
    var dataSend = new FormData();
    dataSend.append("name", petData.name);
    dataSend.append("race", petData.race);
    dataSend.append("client_id", petData.client_id);
    dataSend.append("size", petData.size);
    dataSend.append("description", petData.description);
    if (image) {
      dataSend.append("image", image);
    }
    try {
      const res = await axios.post(global.config.env.API_SERVER + "pets/" + petData.id, dataSend,
        {
          headers: {
            "Api-Token": localStorage.getItem("Api_Token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      dispatch({
        type: UPDATE_PET,
        payload: res.data.pet,
      });
    } catch (error) {
      dispatch({
        type: GET_ERROR_PETS});
    }
};
export const getPetsData = () => async (dispatch, getState) => {
  if(getState().users.userStats?.data.code !== 200){return null}
  if(getState().pets.pets.length === 0){
      dispatch({
        type: LOADING_PET,
      });
      try {
        const res = await axios.get(global.config.env.API_SERVER + "pets", {
          headers: {
            "Api-Token": localStorage.getItem("Api_Token"),
          },
        });
        dispatch({
          type: GET_PETS,
          payload: res.data,
        });
      } catch (error) {
        console.log(error);
      }
  }
};
export const getPetShiftsData = (id) => async (dispatch, getState) => {
  dispatch({
    type: LOADING_PET,
  });
  try {
    const res = await axios.get(global.config.env.API_SERVER + "pets/" + id, {
      headers: {
        "Api-Token": localStorage.getItem("Api_Token"),
      },
    });
    dispatch({
      type: GET_PET,
      payload: res.data[0],
    });
  } catch (error) {
    console.log(error);
  }
};
export const deletePet = (id) => async (dispatch, getState) => {
  dispatch({
    type: LOADING_PET,
  });
  try {
    const res = await axios.delete(global.config.env.API_SERVER + "pets/" + id, {
      headers: {
        "Api-Token": localStorage.getItem("Api_Token"),
      },
    });
    dispatch({
      type: DELETE_PET,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};
export const clearPetsStatus = () => async (dispatch, getState) => {
  dispatch({
    type: CLEAR_STATUS_ACTION,
  });
};

