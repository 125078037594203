import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { deletePet } from '../../redux/reducers/petsReducer';
import {Link} from "react-router-dom"

const Table = () => {
  const users = useSelector((store) => store.users.users);
  const pets = useSelector((store) => store.pets.pets);
  const shifts = useSelector((store) => store.shifts.shifts);
  const clients = useSelector((store) => store.clients.clients);
  const loading_users = useSelector((store) => store.users.loading);
  const loading_pets = useSelector((store) => store.pets.loading);
  const loading_shifts = useSelector((store) => store.shifts.loading);
  const loading_clients = useSelector((store) => store.clients.loading);

  const [searchShift,setSearchShift] = useState('')
  const [searchPet,setSearchPet] = useState('')
  const [searchClient,setSearchClient] = useState('')

  const dispatch = useDispatch();

  return (
    <div className="col-12">
      <div class="page-header row no-gutters py-4">
        <div class="col-12 col-sm-4 text-center text-sm-left mb-0">
          <span class="text-uppercase page-subtitle">Panel</span>
          <h3 class="page-title">Mascotas</h3>
        </div>
      </div>
      <div class="row">
        {/* // Pets */}
        <div class="col-12 position-relative mt-3 mt-lg-3">
          <div class="card card-small ">
            <div class="card-header border-bottom cursor-pointer"  data-bs-toggle="collapse" data-bs-target="#collapsePets" aria-expanded="false" aria-controls="collapsePets">
              <h6 class="m-0">Mascotas</h6>
            </div>
            <div class="card-body p-0 pb-3 mt-4 text-center" id="collapsePets">
              <div class="input-group input-group-seamless input-group-filter">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <i>
                    <svg width="21" height="21" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M21 21L16.514 16.506M19 10.5C19 12.7543 18.1045 14.9163 16.5104 16.5104C14.9163 18.1045 12.7543 19 10.5 19C8.24566 19 6.08365 18.1045 4.48959 16.5104C2.89553 14.9163 2 12.7543 2 10.5C2 8.24566 2.89553 6.08365 4.48959 4.48959C6.08365 2.89553 8.24566 2 10.5 2C12.7543 2 14.9163 2.89553 16.5104 4.48959C18.1045 6.08365 19 8.24566 19 10.5Z" stroke="#C4C4C4" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                    </i>
                  </div>
                </div>
                <input
                  class="navbar-search form-control pl-5"
                  type="text"
                  placeholder="Busca alguna mascota por su nombre..."
                  aria-label="Search"
                  onChange={(e) => setSearchPet(e.target.value)}
                />{" "}
                <Link to="/pets/add" className="ml-4 btn btn-simply">
                  Agregar mascotas
                </Link>
              </div>
              <div class="table table_users mb-0">
                <div class="">
                  <div className="row d-flex align-items-center justify-content-between mb-lg-0 mb-3 ">
                    <div class="font-semibold col-1 text-left d-lg-block d-none border-0">#</div>
                    <div class="font-semibold col text-left d-block border-0">Nombre</div>
                    <div class="font-semibold col text-left d-block border-0">Raza</div>
                    <div class="font-semibold col text-left d-lg-block d-none border-0">Tamaño</div>
                    <div class="font-semibold col text-left d-block border-0">Cliente</div>
                    <div class="font-semibold col text-left d-lg-block d-none border-0">Descripcion</div>
                    <div class="font-semibold col text-left d-block border-0">Acciones</div>
                  </div>
                </div>
                {pets.length > 0 ?
                 pets.sort((a, b) => a.id - b.id).map((item, index) => (
                  (item.name?.includes(searchPet) || item.client.phone.includes(searchPet)) ? (
                  <tr className="row py-3 d-flex align-items-center justify-content-between">
                    <div className="col-1 d-lg-block d-none text-left">
                      <img src={item.image} alt="" />
                    </div>
                    <div className="col text-left"><Link to={"/mascotas/" + item.id}>{item.name}</Link></div>
                    <div className="col text-left">{item.race}</div>
                    <div className="col d-lg-block d-none text-left">{item.size}</div>
                    <div className="col text-left">{item?.client?.name ? item?.client?.name : 'Necesario recargar'}</div>
                    <div className="col d-lg-block d-none text-left">{item.description.slice(0,45) + (item.description.length > 45 ? '...' : '')}</div>
                    <div className="col text-left">
                      <Link to={"/mascotas/actualizar/" + item.id}>
                        <button className="edit pending p-lg-2 p-1 bg-yellow-400 rounded-full mr-2">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7001 0.108521C11.4127 0.109216 11.1284 0.166799 10.8634 0.277948C10.5984 0.389096 10.3581 0.55161 10.1562 0.756114L1.75558 9.15624L1.72265 9.32252L1.14146 12.2444L0.975708 13.0243L1.75611 12.8585L4.67799 12.2773L4.84374 12.2439L13.2444 3.84374C13.4494 3.64225 13.6123 3.40194 13.7235 3.13683C13.8346 2.87173 13.8919 2.58713 13.8919 2.29966C13.8919 2.01219 13.8346 1.7276 13.7235 1.46249C13.6123 1.19738 13.4494 0.957074 13.2444 0.755583C13.0424 0.551112 12.802 0.388659 12.5369 0.277601C12.2719 0.166542 11.9874 0.109077 11.7001 0.108521ZM11.7001 1.12108C11.9678 1.12108 12.2377 1.24327 12.4969 1.50305C13.0138 2.01943 13.0138 2.5799 12.4969 3.0968L12.1155 3.46177L10.5377 1.88449L10.9032 1.50305C11.163 1.24327 11.4323 1.12108 11.7001 1.12108ZM9.79127 2.63196L11.368 4.20924L4.94361 10.6331C4.59679 9.95453 4.04462 9.40273 3.3658 9.05636L9.79127 2.63196ZM2.6858 9.92018C3.00022 10.0467 3.28582 10.2354 3.52546 10.475C3.76511 10.7147 3.95384 11.0003 4.08033 11.3147L2.33677 11.6632L2.6858 9.92018Z" fill="white"/>
                            </svg>
                        </button>
                      </Link>
                      <button onClick={() => dispatch(deletePet(item.id))} className="delete p-lg-2 p-1 bg-red-500 rounded-full">
                          <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M3.69231 5.0769C3.81472 5.0769 3.93211 5.12553 4.01867 5.21209C4.10522 5.29864 4.15385 5.41604 4.15385 5.53844V11.0769C4.15385 11.1993 4.10522 11.3167 4.01867 11.4033C3.93211 11.4898 3.81472 11.5384 3.69231 11.5384C3.5699 11.5384 3.45251 11.4898 3.36596 11.4033C3.2794 11.3167 3.23077 11.1993 3.23077 11.0769V5.53844C3.23077 5.41604 3.2794 5.29864 3.36596 5.21209C3.45251 5.12553 3.5699 5.0769 3.69231 5.0769ZM6 5.0769C6.12241 5.0769 6.23981 5.12553 6.32636 5.21209C6.41292 5.29864 6.46154 5.41604 6.46154 5.53844V11.0769C6.46154 11.1993 6.41292 11.3167 6.32636 11.4033C6.23981 11.4898 6.12241 11.5384 6 11.5384C5.8776 11.5384 5.7602 11.4898 5.67365 11.4033C5.58709 11.3167 5.53847 11.1993 5.53847 11.0769V5.53844C5.53847 5.41604 5.58709 5.29864 5.67365 5.21209C5.7602 5.12553 5.8776 5.0769 6 5.0769ZM8.76924 5.53844C8.76924 5.41604 8.72061 5.29864 8.63405 5.21209C8.5475 5.12553 8.4301 5.0769 8.3077 5.0769C8.18529 5.0769 8.06789 5.12553 7.98134 5.21209C7.89478 5.29864 7.84616 5.41604 7.84616 5.53844V11.0769C7.84616 11.1993 7.89478 11.3167 7.98134 11.4033C8.06789 11.4898 8.18529 11.5384 8.3077 11.5384C8.4301 11.5384 8.5475 11.4898 8.63405 11.4033C8.72061 11.3167 8.76924 11.1993 8.76924 11.0769V5.53844Z" fill="white"/>
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.76923C12 3.01405 11.9027 3.24883 11.7296 3.42194C11.5565 3.59506 11.3217 3.69231 11.0769 3.69231H10.6154V12C10.6154 12.4896 10.4209 12.9592 10.0747 13.3054C9.72844 13.6516 9.25886 13.8462 8.76923 13.8462H3.23077C2.74114 13.8462 2.27156 13.6516 1.92534 13.3054C1.57912 12.9592 1.38462 12.4896 1.38462 12V3.69231H0.923077C0.678262 3.69231 0.443473 3.59506 0.270363 3.42194C0.0972524 3.24883 0 3.01405 0 2.76923V1.84615C0 1.60134 0.0972524 1.36655 0.270363 1.19344C0.443473 1.02033 0.678262 0.923077 0.923077 0.923077H4.15385C4.15385 0.678262 4.2511 0.443473 4.42421 0.270363C4.59732 0.0972525 4.83211 0 5.07692 0L6.92308 0C7.16789 0 7.40268 0.0972525 7.57579 0.270363C7.7489 0.443473 7.84615 0.678262 7.84615 0.923077H11.0769C11.3217 0.923077 11.5565 1.02033 11.7296 1.19344C11.9027 1.36655 12 1.60134 12 1.84615V2.76923ZM2.41662 3.69231L2.30769 3.74677V12C2.30769 12.2448 2.40494 12.4796 2.57806 12.6527C2.75117 12.8258 2.98595 12.9231 3.23077 12.9231H8.76923C9.01405 12.9231 9.24883 12.8258 9.42194 12.6527C9.59505 12.4796 9.69231 12.2448 9.69231 12V3.74677L9.58338 3.69231H2.41662ZM0.923077 2.76923V1.84615H11.0769V2.76923H0.923077Z" fill="white"/>
                          </svg>
                      </button>
                    </div>
                  </tr>
                ):''))
                :
                    <h5 className="my-5 py-5">No hay mascotas creadas.</h5>
                }
              </div>
            </div>
          </div>
          {
            loading_pets ? (
              <section className="loading_section table-loading">
                <div class="sk-chase">
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                </div>
              </section>
            ):''
          }
        </div>
      </div>
    </div>
  )
};

export default Table;
