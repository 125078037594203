import { Link } from "react-router-dom"
import DatePicker, {registerLocale } from "react-datepicker";
import { useState, useEffect } from "react";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

import { useSelector, useDispatch } from "react-redux";

import "react-datepicker/dist/react-datepicker.css";
import { getPetsData } from "../../redux/reducers/petsReducer";
import { addShiftData } from "../../redux/reducers/shiftsReducer";

import es from "date-fns/locale/es";
registerLocale("es", es);


const add_Shifts = () => {

    const [dateWaiting,setDateWaiting] = useState(new Date());

    const [startDate, setStartDate] = useState(new Date());

    const [shift,setShift] = useState({
        pet_id: "",
        cut_type: "",
        price:"",
        washed:false,
        fragrance:false,
        date:"",
        description:"",
        search:false,
        waiting:false,
    });

    const pets = useSelector((store) => store.pets.pets);
    const dispatch = useDispatch();

    const formateDate = (date) => {
        //formate date to dd/mm/yyyy hh:mm
        let day = date.getDate();
        if(day < 10) day = "0" + day;
        let month = date.getMonth() + 1;
        if(month < 10){month = "0" + month}
        let year = date.getFullYear();
        let hours = date.getHours();
        if(hours < 10){hours = "0" + hours}
        let minutes = date.getMinutes();
        if(minutes < 10){minutes = "0" + minutes}
        const response =  day + "-" + month + "-" + year + " " + hours + ":" + minutes;
        return response;
    }

    const handleOnSelect = (item) => {
        setShift({...shift, pet_id: item.id})
    }

    const handleChange = (e) => {
        if(e.target.name === 'fragrance' || e.target.name === 'search'){
            setShift({...shift, [e.target.name]: e.target.checked})
        }
        else if(e.target.name === 'washed'){
            if(e.target.value === 'true'){
                setShift({...shift, [e.target.name]: true})
            }
            else{
                setShift({...shift, [e.target.name]: false})
            }
        }
        else{
            setShift({
                ...shift,
                [e.target.name]:e.target.value
            })
        }
    }

    const createShift = (e) => {
        e.preventDefault()
        dispatch(addShiftData(shift))
        setShift({...shift,
            pet_id: "",
            cut_type: "",
            price:"",
            washed:false,
            fragrance:false,
            description:"",
            search:false,
            waiting:false
        })
        handleChange({"target":{"name":"date","value":formateDate(startDate)}})
        document.getElementById("formCreateShift").reset();
    }

    const getEditedPets = (pets) => {
        const petEdited = []
        pets.map((pet) => {
            petEdited.push(
                {
                    client:pet?.client,
                    description: pet.description,
                    id: pet.id,
                    image: "https://png.pngtree.com/png-vector/20191027/ourlarge/pngtree-cute-dog-avatar-with-a-yellow-background-png-image_1873421.jpg",
                    name: pet.name + " (" + pet?.client?.name + ")",
                    race: pet.race,
                    size: pet.size,
                }
            )
        })
        return petEdited
    }

    useEffect(() => {
        dispatch(getPetsData());
    } ,[]);

    useEffect(() => {
        handleChange({"target":{"name":"date","value":formateDate(startDate)}})
    } ,[startDate]);

    return (
        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered shift_modal" role="document">
                <div class="modal-content">
                    <div className="modal-header d-block d-lg-none">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <section className="add_shifts">
                            <div className="row no-gutters pl-lg-3 overflow-hidden align-items-stretch rounded-3xl">
                                <div className="col-lg-8">
                                    <form id="formCreateShift" className="row align-items-center pr-5 h-full" onSubmit={createShift}>
                                        <div className="col-12 mb-3 mt-lg-0 mt-2 position-relative">
                                            <label className="d-block pl-3 w-100">Nombre de la mascota</label>
                                            <ReactSearchAutocomplete
                                                items={getEditedPets(pets)}
                                                onSelect={handleOnSelect}
                                            />
                                            <Link to="/pets/add" className="text-green-500 btn-pet_add font-medium position-absolute flex items-center h-100 justify-content-center text-end text-xs top-0" style={{marginTop:".9rem", right:'4rem'}}>Agregar nueva mascota</Link>
                                        </div>
                                        <div className="col-lg-8 mt-lg-0">
                                            <label className="d-block pl-3 w-100">Corte</label>
                                            <select onChange={(e) => handleChange(e)} name="cut_type" className="w-100">
                                                <option value="">Seleccionar una opción</option>
                                                <option value="sin corte">Sin corte</option>
                                                <option value="raza">Raza</option>
                                                <option value="pelado">Pelado</option>
                                                <option value="cm">1 Centimetro</option>
                                                <option value="personalizado">Personalizado</option>
                                            </select>
                                        </div>
                                        <div className="col-lg-4 mt-lg-0 mt-3">
                                            <label className="d-block pl-3 w-100" >Precio</label>
                                            <input  onChange={(e) => handleChange(e)} name="price" className="w-100" type="text" />
                                        </div>
                                        <div className="col-lg-8 mt-3">
                                            <label className="d-block pl-3 w-100">Fecha/Horario</label>
                                            <DatePicker
                                                selected={startDate}
                                                onChange={(date) => setStartDate(date)}
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={15}
                                                locale={es}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                            />
                                        </div>
                                        <div className="col-lg-4 mt-3">
                                            <label className="d-block pl-3 w-100">Baño</label>
                                            <select onChange={(e) => handleChange(e)} name="washed" className="w-100">
                                                <option value="">Seleccionar una opción</option>
                                                <option value='true'>Si</option>
                                                <option value='false'>No</option>
                                            </select>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <textarea name="description" onChange={(e) => handleChange(e)} id="" cols="30" rows="5" placeholder="Información extra no obligatoria sobre el turno.."></textarea>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <input type="checkbox" onChange={(e) => handleChange(e)} className="mr-2" name="search" id="" />
                                            <label for="search" className="mr-5">Hay que buscarlo</label>
                                            <input type="checkbox" onChange={(e) => handleChange(e)} className="mr-2" name="fragrance" id="" />
                                            <label for="fragrance"  className="mr-4">Sin perfume</label>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <button type="submit" data-dismiss="modal" className="btn btn-simply">
                                                Agregar turno
                                            </button>
                                            <button onClick={() => setShift({...shift, waiting: true})} data-dismiss="modal" type="submit" className="btn btn-warning ml-2">
                                                Agregar turno en espera
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-4 d-none d-lg-block">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <img className="w-100 img-responsive h-full object-cover object-center" src="/images/form.jpg" alt="" />
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
       
    )
}

export default add_Shifts