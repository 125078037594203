import { useEffect, useState } from "react";
import Main from "../components/home/main";
import Draft from "../components/home/draft";
import Shifts from "../components/home/shifts";
import Panel from "../components/shifts/list_shifts";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../redux/reducers/userReducer";
import { getPetsData } from "../redux/reducers/petsReducer";
import { getShiftsData } from "../redux/reducers/shiftsReducer";
import { getClientsData } from "../redux/reducers/clientsReducer";

import Header from "../components/header.jsx";
import Sidebar from "../components/sidebar.jsx";

const Home = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector((store) => store.users.loading);
  const logged = useSelector((store) => store.users.logged);

  useEffect(() => {
    // dispatch(getUserData());
    dispatch(getPetsData());
    dispatch(getShiftsData());
    dispatch(getClientsData());
    if(!localStorage.getItem("Api_Token")){
      history.push("/login")
    }
    if(!loading){
      if(!logged){
        history.push("/login")
      }
    }
  }, [loading]);

  return !loading ? (
    <div>
      <Header />
      <Sidebar />
        <div class="main-content-container container-fluid px-4">
        <Main />
          {/* <Panel /> */}
        {/* <div class="row"> */}
          {/* <Draft /> */}
          {/* <Shifts /> */}
        {/* </div> */}
      </div>
    </div>
  ) : '';
};

export default Home;
