import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Table from '../components/tables/clients'

import { getUserData, getUsersData } from "../redux/reducers/userReducer";
import { getPetsData } from "../redux/reducers/petsReducer";
import { getClientsData } from "../redux/reducers/clientsReducer";
import { getShiftsData } from "../redux/reducers/shiftsReducer";
import { useHistory } from "react-router-dom";

const Home = () =>{
  const dispatch = useDispatch();
  
  const loading = useSelector((store) => store.users.loading);
  const logged = useSelector((store) => store.users.logged);
  const history = useHistory();
  
    useEffect(() => {
      // dispatch(getUserData());
      dispatch(getPetsData());
      dispatch(getShiftsData());
      dispatch(getClientsData());
      if(!localStorage.getItem("Api_Token")){
        history.push("/login")
      }
      if(!loading){
        if(!logged){
          history.push("/login")
        }
      }
    }, [loading]);

    return(
        <div class="main-content-container container-fluid px-4">
            <div class="row">
                <Table />
            </div>
        </div>
    )
}

export default Home