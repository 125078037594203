import { useDispatch, useSelector } from "react-redux";
import {deleteShift} from '../redux/reducers/shiftsReducer'
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
const modal = (props) => {

    const dispatch = useDispatch();
    const history = useHistory()
    const userData = useSelector((store) => store.users.userStats).data;


    return (
        <div class="modal fade" id={"shift-" + (props.data.element.shift_id)} tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalCenterTitle">Descripción</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body text-center text-lg-start">
                        <p className={(props.data.element.shift_search === 1 ? 'color-pending' : 'color-success') + " font-semibold"}>{props.data.element.shift_search === 1 ? 'Hay que buscarlo' : 'Lo traen'}</p>
                        <p className={"mt-2" + (props.data.element.shift_search === 0 ? ' d-none' : '')}> Dirección de  <span className="font-bold"> <Link data-dismiss="modal" onClick={() => history.push("/clientes/" + props.data.element.client_id) } > {props.data.element.client_name}</Link> </span> </p> 
                        <p className={"mt-2" + (props.data.element.shift_search === 0 ? ' d-none' : '')}> <span className="font-bold cursor-pointer">  {props.data.element.client_address} </span> </p> 
                        <p className={"mt-3" + (props.data?.element?.client_phone?.toLowerCase() === 'sin definir' ? ' d-none' : '')}>Whatsapp de {props.data.element.client_name}:<a target="_blank" href={"https://api.whatsapp.com/send?text=Hola!&phone=+54" + props.data.element.client_phone} className="font-bold px-1 py-2"> {props.data.element.client_phone} </a></p>
                        <p className="mt-4"> Nombre: <span data-dismiss="modal" onClick={() => history.push("/mascotas/" + props.data.element.pet_id)} className="font-bold cursor-pointer"> {props.data.element.pet_name}</span> </p>
                        <img style={{width:"100px",height:"100px",objectFit:"cover",margin:"1rem auto",borderRadius:"50%",border:"4px solid #0b89bb"}} src={props.data.element.pet_image} alt="" />
                        <p className="mt-4" > Cliente: <span data-dismiss="modal" onClick={() => history.push("/clientes/" + props.data.element.client_id)} className="font-bold cursor-pointer"> {props.data.element.client_name}</span> </p>
                        <p className="mt-2"> Raza:  <span className="font-bold"> {props.data.element.pet_race} </span></p>
                        <p className="mt-2"> Precio:  <span className="font-bold"> $ {props.data.element.shift_price}</span></p>
                        <p className="mt-2"> Baño:  <span className="font-bold"> {props.data.element.shift_washed ? 'Si' : 'No'} </span></p>
                        <p className="mt-2"> Fecha:  <span className="font-bold"> {props.data.element.shift_date} </span></p>
                        <p className="mt-2"> Descripción:  <span className="font-bold"> {props.data.element.shift_description}. </span></p>
                        <p className="mt-2"> Corte:  <span className="font-bold"> {props.data.element.shift_cut_type} </span></p>
                        <br />
                        <span className="font-bold"> Además {props.data.element.fragrance === '0' ? 'no' : '' } quiere perfume.</span>
                    </div>
                    <div class="modal-footer d-flex justify-content-center">
                        {/* <button type="button" style={{minWidth:"90px"}} class="btn btn-modal btn-secondary" data-dismiss="modal">Cerrar</button> */}
                        <button style={{minWidth:"90px"}} class={"btn btn-modal btn-success " + (props.data?.element?.client_phone?.toLowerCase() === 'sin definir' ? ' d-none' : '')} >
                            <a target="_blank" 
                            href={"https://api.whatsapp.com/send?text=¡Hola 🖐! %0A *¡Tu mascota ya está lista 🐶🐕!* %0A " + 
                            (props.data.element.shift_search === 1 ?  "En breves va a llegar a su domicilio. %0A" : '' ) + (userData?.user?.lastname ? " Te dejamos nuestro instagram por si queres seguirnos: " + userData?.user?.lastname +  "%0A ¡No olvides de subir una foto así podemos compartirla!" : '') + "&phone=+54" + props.data.element.client_phone} className="font-bold px-1 py-2">Avisar</a> </button>
                        <button onClick={() => history.push("/shifts/update/" + props.data.element.shift_id)} type="button" style={{minWidth:"90px"}} class="btn btn-modal btn-warning" data-dismiss="modal">Editar turno</button>
                        <button onClick={() => dispatch(deleteShift(props.data.element.shift_id))} type="button" style={{minWidth:"90px"}} class="btn btn-modal btn-danger" data-dismiss="modal">Eliminar turno</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default modal;